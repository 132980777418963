import Vue from "vue";
import App from "@/App.vue";
import router from "@/router";
import store from "@/store";

// plugins
// import "@/plugins/pretty-checkbox-vue";
import "@/plugins/bootstrap";
import "@/plugins/clipboard";
import "@/plugins/css";
import "@/plugins/js-cookie";
import "@/plugins/fontawesome";
import "@/plugins/lodash";
import "@/plugins/vueCookies";
import "@/plugins/vueEasytable";
import "@/plugins/vueWorkers";
import axiosInstance from "@/plugins/axios-config";

// mixins
import refreshData from "@/mixins/refreshData";

Vue.config.productionTip = false;
Vue.mixin(refreshData);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");

Vue.prototype.$axios = axiosInstance;
