import _ from "lodash";
import Vue from "vue";
import Vuex from "vuex";
// import _ from "lodash";
import router from "@/router";
import API_MODULES_AUTH from "@/api/apiServices/Authentication/authenthication";
import SystemManagement from "./modules/SystemManagement";
import HumanResourceManagement from "./modules/HumanResourceManagement";
import ProductManagement from "./modules/ProductManagement";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    SystemManagement,
    HumanResourceManagement,
    ProductManagement,
  },
  state: {
    auth: {
      userID: "",
      token: "",
      cookie: "",
      isTokenValid: false,
    },
    alertStatus: {
      showAlert: false,
      message: "",
      variant: "",
      icon: "",
    },
    isOverlayVisible: false,
    currentRoute: null,
    currentPermissionItemId: null,
    viewBus: {
      selectedModuleID: null,
      selectedTemplateGroupID: null,
      selectedActualGroupID: null,
      selectedCompanySN: null,
      selectedAttribute: {
        type: null,
      },
      selectedPostProcessing: {
        type: null,
      },
      selectedProduct: {
        type: null,
        category: null,
        class: null,
        model: null,
      },
      selectedProductWithAttributeTypeConfiguration: {
        type1: null,
        type2: null,
        type3: null,
      },
    },
    toResetTableCheckboxOption: false,
  },
  getters: {
    isAuthenticated: (state) => () => {
      // 會從getTokenFromCookie中去抓取token，如果token存在isAuthenticated為true；否則為false
      return !!state.auth.token;
    },
    /**
     * 抓取此頁面的 permissionID
     * @returns {string} permissionID
     */
    getPermissionID: () => (listOfPermissionIDs) => {
      const pathPart = router.currentRoute.path.split("/").pop();
      const matchedID =
        _.find(listOfPermissionIDs, (item) => item.startsWith(pathPart)) ||
        null;
      return matchedID;
    },
    inputState:
      () =>
      (requireData, data, isvalidated = null) => {
        if (data === null || data === "" || data === undefined) {
          if (!requireData) {
            return null;
          }
          return false;
        } else {
          if (isvalidated) {
            // validator
            return isvalidated(data);
          }
        }
        return true;
      },
    initializeForm: () => (columns) => {
      const form = {};
      const filteredColumns = columns.filter((column) => column.key !== "e");
      filteredColumns.forEach((column) => {
        if (column.inputType === "checkbox") form[column.key] = true;
        else form[column.key] = null;
      });
      return form;
    },
    populateMissingProperties: () => (form, columns) => {
      // 遍歷 columns 中的屬性，如果 form 中缺少該屬性，則補充
      for (const column of columns) {
        const property = column["key"];
        if (!(property in form)) {
          form = {
            ...form,
            [property]: null,
          };
        }
      }
      return form;
    },
    isFormValidated:
      (state, getters) =>
      (form, columns, action = "Create") => {
        // 此method目的在於判斷form，必填項目是否都有被填寫（不能為空），如果不是必填項目可忽略
        for (const key in form) {
          if (key === "token" || key === "btn_act" || key === "e") continue;
          const column = columns.find((column) => column.key === key);
          const isRequiredToInput =
            action === "Create"
              ? column?.isRequiredToInput?.create
              : column?.isRequiredToInput?.edit;
          const hasDatabase = column?.hasDatabase; //TODO
          const isValidated = getters.inputState(
            isRequiredToInput,
            form[key],
            column?.validator
          );
          if (key === "mainValue") console.log("column: ", column);
          if (!isRequiredToInput) continue; //非必填，則忽略
          if (hasDatabase === false) continue; //非必填，則忽略 //TODO
          if (!isValidated) {
            console.log(key, form[key]);
            return false;
          }
        }
        return true;
      },
    getPropertyOfFormInputCreate:
      (state, getters) => (column, formOfCreate) => {
        const properties = {
          condition: !["checkbox", "option"].includes(column.inputType),
          type: ["checkbox", "option"].includes(column.inputType)
            ? "text"
            : column.inputType,
          disabled: !column.hasFunctionality.create,
          state: getters.inputState(
            column.isRequiredToInput.create,
            formOfCreate[column.key]
          ),
          class: {
            "is-valid": getters.inputState(
              column.isRequiredToInput.create,
              formOfCreate[column.key]
            ),
            "is-invalid": !getters.inputState(
              column.isRequiredToInput.create,
              formOfCreate[column.key]
            ),
          },
          min: 0,
          max: Infinity,
        };
        return properties;
      },
    /**
     * 根據指定的 key 在 columns 中查找並返回對應的列。
     * @param {string} key - 要查找的列的 key。
     * @param {Array<Object>} columns - 包含多個列定義的數組，每個列定義是一個對象。
     * @returns {Object|undefined} 返回對應的列對象，如果沒有找到則返回 undefined。
     */
    columnOf: () => (key, columns) => {
      return columns.find((column) => column.key === key);
    },
    /**
     * 根據列和 columns 數組中的 key，獲取對應列的選項。
     * @returns {Function} 返回一個函數，該函數接收 column 和 columns 作為參數，並返回對應列的選項。
     */
    optionsByColumn: (state, getters) => (column, columns) => {
      const key = column.key;
      return getters.columnOf(key, columns)?.options;
    },
    updateOptionOfColumnOf: () => (key, options, columns) => {
      const cloneOptions = _.cloneDeep(options); // 防止覆蓋原始資料
      // 更新 columns 中的 options 属性
      const updatedColumns = columns.map((column) => {
        if (column.field === key) {
          column.options.option = cloneOptions;
        }
        return column;
      });
      return updatedColumns;
    },
    formatValue: () => (COLUMNS, list, data, columnName) => {
      const valueField = COLUMNS[columnName].data.options.valueField;
      const textField = COLUMNS[columnName].data.options.textField;
      const formattedData = _.find(list, { [valueField]: data });
      return formattedData ? formattedData[textField] : "-";
    },
    renameCRUDKey: () => (form) => {
      const { create, read, update, delete: del, ...rest } = form;
      return _.isUndefined(form.create)
        ? form
        : {
            actionCreate: create,
            actionRead: read,
            actionUpdate: update,
            actionDelete: del,
            ...rest,
          };
    },
  },
  mutations: {
    SET_OVERLAY_VISILBILITY(state, bool) {
      state.isOverlayVisible = bool;
    },
    SET_TO_RESET_TABLE_CHECKBOX_OPTION(state, bool) {
      state.toResetTableCheckboxOption = bool;
    },
    SET_CURRENT_ROUTE(state, route) {
      state.currentRoute = route;
    },
    SET_TOKEN(state, token) {
      state.auth.token = token;
    },
    SET_TOKEN_VALIDITY(state, validity) {
      state.auth.isTokenValid = validity;
    },
    SET_COOKIE(state, cookie) {
      state.auth.cookie = cookie;
    },
    SET_USERID(state, id) {
      state.auth.userID = id;
    },
    SET_SELECTED_MODULE_ID(state, stringID) {
      state.viewBus.selectedModuleID = stringID;
    },
    SET_SELECTED_ACTUAL_GROUP_ID(state, stringID) {
      state.viewBus.selectedActualGroupID = stringID;
    },
    SET_SELECTED_TEMPLATE_GROUP_ID(state, stringID) {
      state.viewBus.selectedTemplateGroupID = stringID;
    },
    SET_SELECTED_COMPANY_SN(state, serialNo) {
      state.viewBus.selectedCompanySN = serialNo;
    },
    SET_SELECTED_PRODUCT_TYPE(state, serialNo) {
      state.viewBus.selectedProduct.type = serialNo;
    },
    SET_SELECTED_PRODUCT_CATEGORY(state, serialNo) {
      state.viewBus.selectedProduct.category = serialNo;
    },
    SET_SELECTED_PRODUCT_CLASS(state, serialNo) {
      state.viewBus.selectedProduct.class = serialNo;
    },
    SET_SELECTED_PRODUCT_MODEL(state, serialNo) {
      state.viewBus.selectedProduct.model = serialNo;
    },
    SET_SELECTED_ATTRIBUTE_TYPE(state, object) {
      state.viewBus.selectedAttribute.type = object;
    },
    SET_SELECTED_POST_PROCESSING_TYPE(state, object) {
      state.viewBus.selectedPostProcessing.type = object;
    },
    SET_SELECTED_PRODUCT_WITH_ATTRIBUTE_TYPE_CONFIGURATION(state, object) {
      state.viewBus.selectedProductWithAttributeTypeConfiguration = object;
    },
  },
  actions: {
    // FREQUENTLY USED
    // ALERT
    showSuccessMessage({ state }, message) {
      state.alertStatus.showAlert = true;
      state.alertStatus.message = message;
      state.alertStatus.icon = "check-circle-fill";
      state.alertStatus.variant = "success";
      setTimeout(() => {
        state.alertStatus.showAlert = false;
      }, 5000);
    },
    showErrorMessage({ state }, message) {
      state.alertStatus.showAlert = true;
      state.alertStatus.message = message;
      state.alertStatus.icon = "exclamation-triangle-fill";
      state.alertStatus.variant = "danger";
      setTimeout(() => {
        state.alertStatus.showAlert = false;
      }, 10000);
    },
    showOverlay({ commit }) {
      commit("SET_OVERLAY_VISILBILITY", true);
    },
    hideOverlay({ commit }) {
      // 延時關閉overlay
      setTimeout(() => {
        commit("SET_OVERLAY_VISILBILITY", false);
      }, 500);
    },
    // NOTE: 只能傳遞一個 parameter，多餘一個 parameter 必須包裝成 object
    async postOneData(
      { dispatch },
      { form, actionCRUD, actionsAfterDataPosted }
    ) {
      let responseAllData = true;
      let errorMessage = "Something Wrong!";

      try {
        const responseData = await actionCRUD(form);
        if (responseData === undefined) {
          responseAllData = false;
        } else if (!responseData.success) {
          errorMessage = responseData.msg;
          responseAllData = false;
        }
      } catch (error) {
        errorMessage = error;
        responseAllData = false;
      }

      if (responseAllData) {
        await actionsAfterDataPosted();
      } else {
        dispatch("showErrorMessage", errorMessage);
      }
    },
    async postManyData(
      { getters, dispatch },
      { forms, actionCRUD, actionsAfterDataPosted }
    ) {
      let responseAllData = true;
      let errorMessage = "Something Wrong!";
      const MAX_CONCURRENT_REQUESTS = 1; // 最大並發請求數量
      let runningRequests = 0; // 當前正在運行的請求數量

      const asyncQueue = forms.map(async (form) => {
        // 如果當前正在運行的請求數量達到了最大並發數，則等待
        while (runningRequests >= MAX_CONCURRENT_REQUESTS) {
          await new Promise((resolve) => setTimeout(resolve, 1)); // 每隔100毫秒檢查一次
        }
        // 發送請求前增加計數器
        runningRequests++;
        try {
          form = getters.renameCRUDKey(form);
          const responseData = await actionCRUD(form);
          if (responseData === undefined) {
            responseAllData = false;
          } else if (!responseData.success) {
            errorMessage = responseData.msg;
            responseAllData = false;
          }
        } catch (error) {
          errorMessage = error;
          responseAllData = false;
        } finally {
          // 請求完成後減少計數器
          runningRequests--;
        }
      });

      // 等待所有非同步操作完成
      await Promise.all(asyncQueue);

      if (responseAllData) {
        await actionsAfterDataPosted();
      } else {
        dispatch("showErrorMessage", errorMessage);
      }
    },
    async setCurrentRouteAndPermissionItemId({ commit }, { name }) {
      if (router.currentRoute.name !== name) {
        await router.push({ name });
      }
      commit("SET_CURRENT_ROUTE", router.currentRoute);
    },
    // AUTHENTHICATION
    generateAndUpdateDocumentCookie({ dispatch }, token) {
      const expDate = new Date();
      expDate.setTime(expDate.getTime() + 12 * 60 * 60 * 1000); // 12 hours
      const cookie = `token=${token};expires=${expDate.toUTCString()};path=/;SameSite=Strict`;
      document.cookie = cookie;
      localStorage.setItem("token_expiry", expDate.toUTCString()); // 保存過期時間到local storage

      dispatch("setTokenUserIDFromCookie");
    },
    setTokenUserIDFromCookie({ dispatch, commit }) {
      let token = "";
      const cookies = document.cookie.split(";").map((cookie) => cookie.trim());
      for (const cookie of cookies) {
        token = cookie.split("token=")[1];
      }
      // TIPS
      // const userID = dispatch("getUserIDFromToken", token); 不能直接這樣獲取value
      // 在 Vuex 中，dispatch 方法是用于触发 action 的，并且 action 是异步执行的，所以不能直接通过 dispatch 获取返回值。但是你可以通过 return 语句在 action 中返回一个 Promise，然后在调用 dispatch 的地方使用 then 来获取返回值。
      commit("SET_COOKIE", document.cookie);
      commit("SET_TOKEN", token);
      dispatch("setUserIDFromToken", token);
    },
    setUserIDFromToken({ commit }, token) {
      const parts = token?.split("|");
      commit("SET_USERID", parts?.length > 1 ? parts[0] : "");
    },
    async login({ getters, dispatch }, data) {
      const requestData = { ...data };
      const responseData = await API_MODULES_AUTH.Authentication.login(
        requestData
      );
      if (responseData?.msg) {
        await dispatch("generateAndUpdateDocumentCookie", responseData.msg);
        if (getters.isAuthenticated()) {
          await dispatch("setCurrentRouteAndPermissionItemId", {
            name: "Dashboard",
          });
        }
      }
      return responseData;
    },
    async logout({ state, commit }) {
      if (!state.auth.token || !state.auth.isTokenValid) {
        commit("SET_TOKEN_VALIDITY", false);
        return undefined;
      } else {
        const responseData = await API_MODULES_AUTH.Authentication.logout();
        return responseData;
      }
    },
    async register(context, data) {
      const requestData = { ...data };
      const responseData = await API_MODULES_AUTH.Authentication.register(
        requestData
      );
      return responseData;
    },
    async checkTokenValid({ state, commit }) {
      if (!state.auth.token) commit("SET_TOKEN_VALIDITY", false);
      else {
        let haveError = false;
        try {
          await API_MODULES_AUTH.Authentication.checkTokenValid();
        } catch (error) {
          if (error) haveError = true;
        }

        if (haveError) {
          const now = new Date();
          now.setTime(now.getTime() - 1);
          const expires = now.toUTCString();
          document.cookie = "token=; expires=" + expires + "; path=/";
          commit("SET_COOKIE", document.cookie);
          commit("SET_TOKEN", "");
          commit("SET_TOKEN_VALIDITY", false);
        } else commit("SET_TOKEN_VALIDITY", true);
      }
    },
  },
});
