import store from "@/store";
import axios from "axios";
import {
  headers,
  replaceUrl,
  postRequestWithRetry,
  postCRUDRequest,
} from "@/api/apiServices/commonService";
import { API_ROUTES } from "@/api/apiConfig";
import REQUEST_MODELS from "@/api/requestModels/ProductManagement/product";

const Type = {
  async getAll() {
    const token = store.state.auth.token;
    const reqModel = new REQUEST_MODELS.TYPE_ALL(token);
    const url = API_ROUTES.PRODUCT.TYPE.ALL;
    try {
      const response = await postRequestWithRetry(url, reqModel);
      return response;
    } catch (error) {
      console.error("Error in Type getAll: ", error);
    }
  },
  async create(requestData, requestPermissionID) {
    const reqModel = new REQUEST_MODELS.TYPE_CREATE(
      requestData.token || "",
      requestData.product0_SN || 0,
      requestData.product0_Id || "",
      requestData.product0_ChName || "",
      requestData.product0_EnName || ""
    );
    let url = API_ROUTES.PRODUCT.TYPE.CREATE;
    url = replaceUrl(url, "permissionId", requestPermissionID);
    return await postCRUDRequest(url, reqModel);
  },
  async read(requestID) {
    const token = store.state.auth.token;
    const reqModel = new REQUEST_MODELS.TYPE_READ(token);
    let url = API_ROUTES.PRODUCT.TYPE.READ;
    url = replaceUrl(url, "id", requestID);
    try {
      const response = await axios.post(url, reqModel, {
        headers: headers,
      });
      return response.data;
    } catch (error) {
      console.error("Error in Type: ", error);
    }
  },
  async update(requestData, requestPermissionID) {
    const reqModel = new REQUEST_MODELS.TYPE_UPDATE(
      requestData.token || "",
      requestData.product0_SN || 0,
      requestData.product0_Id || "",
      requestData.product0_ChName || "",
      requestData.product0_EnName || ""
    );
    let url = API_ROUTES.PRODUCT.TYPE.UPDATE;
    url = replaceUrl(url, "permissionId", requestPermissionID);
    return await postCRUDRequest(url, reqModel);
  },
  async delete(requestData, requestPermissionID) {
    const reqModel = new REQUEST_MODELS.TYPE_DELETE(
      requestData.token || "",
      requestData.product0_SN || 0,
      requestData.product0_Id || "",
      requestData.product0_ChName || "",
      requestData.product0_EnName || ""
    );
    let url = API_ROUTES.PRODUCT.TYPE.DELETE;
    url = replaceUrl(url, "permissionId", requestPermissionID);
    return await postCRUDRequest(url, reqModel);
  },
};

const Category = {
  async getAll() {
    const token = store.state.auth.token;
    const reqModel = new REQUEST_MODELS.CATEGORY_ALL(token);
    const url = API_ROUTES.PRODUCT.CATEGORY.ALL;
    try {
      const response = await postRequestWithRetry(url, reqModel);
      return response;
    } catch (error) {
      console.error("Error in Category getAll: ", error);
    }
  },
  async create(requestData, requestPermissionID) {
    const reqModel = new REQUEST_MODELS.CATEGORY_CREATE(
      requestData.token || "",
      requestData.product1_SN || 0,
      requestData.product1_Id || "",
      requestData.product1_ChName || "",
      requestData.product1_EnName || "",
      requestData.product0_SN || 0
    );
    let url = API_ROUTES.PRODUCT.CATEGORY.CREATE;
    url = replaceUrl(url, "permissionId", requestPermissionID);
    return await postCRUDRequest(url, reqModel);
  },
  async read(requestID) {
    const token = store.state.auth.token;
    const reqModel = new REQUEST_MODELS.CATEGORY_READ(token);
    let url = API_ROUTES.PRODUCT.CATEGORY.READ;
    url = replaceUrl(url, "id", requestID);
    try {
      const response = await axios.post(url, reqModel, {
        headers: headers,
      });
      return response.data;
    } catch (error) {
      console.error("Error in Category: ", error);
    }
  },
  async update(requestData, requestPermissionID) {
    const reqModel = new REQUEST_MODELS.CATEGORY_UPDATE(
      requestData.token || "",
      requestData.product1_SN || 0,
      requestData.product1_Id || "",
      requestData.product1_ChName || "",
      requestData.product1_EnName || "",
      requestData.product0_SN || 0
    );
    let url = API_ROUTES.PRODUCT.CATEGORY.UPDATE;
    url = replaceUrl(url, "permissionId", requestPermissionID);
    return await postCRUDRequest(url, reqModel);
  },
  async delete(requestData, requestPermissionID) {
    const reqModel = new REQUEST_MODELS.CATEGORY_DELETE(
      requestData.token || "",
      requestData.product1_SN || 0,
      requestData.product1_Id || "",
      requestData.product1_ChName || "",
      requestData.product1_EnName || "",
      requestData.product0_SN || 0
    );
    let url = API_ROUTES.PRODUCT.CATEGORY.DELETE;
    url = replaceUrl(url, "permissionId", requestPermissionID);
    return await postCRUDRequest(url, reqModel);
  },
};

const Class = {
  async getAll() {
    const token = store.state.auth.token;
    const reqModel = new REQUEST_MODELS.CLASS_ALL(token);
    const url = API_ROUTES.PRODUCT.CLASS.ALL;
    try {
      const response = await postRequestWithRetry(url, reqModel);
      return response;
    } catch (error) {
      console.error("Error in Class getAll: ", error);
    }
  },
  async create(requestData, requestPermissionID) {
    const reqModel = new REQUEST_MODELS.CLASS_CREATE(
      requestData.token || "",
      requestData.product2_SN || 0,
      requestData.product2_Id || "",
      requestData.product2_ChName || "",
      requestData.product2_EnName || "",
      requestData.product1_SN || 0
    );
    let url = API_ROUTES.PRODUCT.CLASS.CREATE;
    url = replaceUrl(url, "permissionId", requestPermissionID);
    return await postCRUDRequest(url, reqModel);
  },
  async read(requestID) {
    const token = store.state.auth.token;
    const reqModel = new REQUEST_MODELS.CLASS_READ(token);
    let url = API_ROUTES.PRODUCT.CLASS.READ;
    url = replaceUrl(url, "id", requestID);
    try {
      const response = await axios.post(url, reqModel, {
        headers: headers,
      });
      return response.data;
    } catch (error) {
      console.error("Error in Class: ", error);
    }
  },
  async update(requestData, requestPermissionID) {
    const reqModel = new REQUEST_MODELS.CLASS_UPDATE(
      requestData.token || "",
      requestData.product2_SN || 0,
      requestData.product2_Id || "",
      requestData.product2_ChName || "",
      requestData.product2_EnName || "",
      requestData.product1_SN || 0
    );
    let url = API_ROUTES.PRODUCT.CLASS.UPDATE;
    url = replaceUrl(url, "permissionId", requestPermissionID);
    return await postCRUDRequest(url, reqModel);
  },
  async delete(requestData, requestPermissionID) {
    const reqModel = new REQUEST_MODELS.CLASS_DELETE(
      requestData.token || "",
      requestData.product2_SN || 0,
      requestData.product2_Id || "",
      requestData.product2_ChName || "",
      requestData.product2_EnName || "",
      requestData.product1_SN || 0
    );
    let url = API_ROUTES.PRODUCT.CLASS.DELETE;
    url = replaceUrl(url, "permissionId", requestPermissionID);
    return await postCRUDRequest(url, reqModel);
  },
};

const Model = {
  async getAll() {
    const token = store.state.auth.token;
    const reqModel = new REQUEST_MODELS.MODEL_ALL(token);
    const url = API_ROUTES.PRODUCT.MODEL.ALL;
    try {
      const response = await postRequestWithRetry(url, reqModel);
      return response;
    } catch (error) {
      console.error("Error in Model getAll: ", error);
    }
  },
  async create(requestData, requestPermissionID) {
    const reqModel = new REQUEST_MODELS.MODEL_CREATE(
      requestData.token || "",
      requestData.product3_SN || 0,
      requestData.product3_Id || "",
      requestData.product3_ChName || "",
      requestData.product3_EnName || "",
      requestData.product1_SN || 0,
      requestData.product2_SN || 0
    );
    let url = API_ROUTES.PRODUCT.MODEL.CREATE;
    url = replaceUrl(url, "permissionId", requestPermissionID);
    return await postCRUDRequest(url, reqModel);
  },
  async read(requestID) {
    const token = store.state.auth.token;
    const reqModel = new REQUEST_MODELS.MODEL_READ(token);
    let url = API_ROUTES.PRODUCT.MODEL.READ;
    url = replaceUrl(url, "id", requestID);
    try {
      const response = await axios.post(url, reqModel, {
        headers: headers,
      });
      return response.data;
    } catch (error) {
      console.error("Error in Model: ", error);
    }
  },
  async update(requestData, requestPermissionID) {
    const reqModel = new REQUEST_MODELS.MODEL_UPDATE(
      requestData.token || "",
      requestData.product3_SN || 0,
      requestData.product3_Id || "",
      requestData.product3_ChName || "",
      requestData.product3_EnName || "",
      requestData.product1_SN || 0,
      requestData.product2_SN || 0
    );
    let url = API_ROUTES.PRODUCT.MODEL.UPDATE;
    url = replaceUrl(url, "permissionId", requestPermissionID);
    return await postCRUDRequest(url, reqModel);
  },
  async delete(requestData, requestPermissionID) {
    const reqModel = new REQUEST_MODELS.MODEL_DELETE(
      requestData.token || "",
      requestData.product3_SN || 0,
      requestData.product3_Id || "",
      requestData.product3_ChName || "",
      requestData.product3_EnName || "",
      requestData.product1_SN || 0,
      requestData.product2_SN || 0
    );
    let url = API_ROUTES.PRODUCT.MODEL.DELETE;
    url = replaceUrl(url, "permissionId", requestPermissionID);
    return await postCRUDRequest(url, reqModel);
  },
};

const AttributeType = {
  async getAll() {
    const token = store.state.auth.token;
    const reqModel = new REQUEST_MODELS.ATTRIBUTE_TYPE_ALL(token);
    const url = API_ROUTES.PRODUCT.ATTRIBUTE_TYPE.ALL;
    try {
      const response = await postRequestWithRetry(url, reqModel);
      return response;
    } catch (error) {
      console.error("Error in AttributeType getAll: ", error);
    }
  },
  async create(requestData, requestPermissionID) {
    const reqModel = new REQUEST_MODELS.ATTRIBUTE_TYPE_CREATE(
      requestData.token || "",
      requestData.sn || 0,
      requestData.attributeId || "",
      requestData.attributeName || "",
      requestData.annotation || ""
    );
    let url = API_ROUTES.PRODUCT.ATTRIBUTE_TYPE.CREATE;
    url = replaceUrl(url, "permissionId", requestPermissionID);
    return await postCRUDRequest(url, reqModel);
  },
  async read(requestID) {
    const token = store.state.auth.token;
    const reqModel = new REQUEST_MODELS.ATTRIBUTE_TYPE_READ(token);
    let url = API_ROUTES.PRODUCT.ATTRIBUTE_TYPE.READ;
    url = replaceUrl(url, "id", requestID);
    try {
      const response = await axios.post(url, reqModel, {
        headers: headers,
      });
      return response.data;
    } catch (error) {
      console.error("Error in AttributeType: ", error);
    }
  },
  async update(requestData, requestPermissionID) {
    const reqModel = new REQUEST_MODELS.ATTRIBUTE_TYPE_UPDATE(
      requestData.token || "",
      requestData.sn || 0,
      requestData.attributeId || "",
      requestData.attributeName || "",
      requestData.annotation || ""
    );
    let url = API_ROUTES.PRODUCT.ATTRIBUTE_TYPE.UPDATE;
    url = replaceUrl(url, "permissionId", requestPermissionID);
    return await postCRUDRequest(url, reqModel);
  },
  async delete(requestData, requestPermissionID) {
    const reqModel = new REQUEST_MODELS.ATTRIBUTE_TYPE_DELETE(
      requestData.token || "",
      requestData.sn || 0,
      requestData.attributeId || "",
      requestData.attributeName || "",
      requestData.annotation || ""
    );
    let url = API_ROUTES.PRODUCT.ATTRIBUTE_TYPE.DELETE;
    url = replaceUrl(url, "permissionId", requestPermissionID);
    return await postCRUDRequest(url, reqModel);
  },
};

const AttributeValue = {
  async getAll() {
    const token = store.state.auth.token;
    const reqModel = new REQUEST_MODELS.ATTRIBUTE_VALUE_ALL(token);
    const url = API_ROUTES.PRODUCT.ATTRIBUTE_VALUE.ALL;
    try {
      const response = await postRequestWithRetry(url, reqModel);
      return response;
    } catch (error) {
      console.error("Error in AttributeValue getAll: ", error);
    }
  },
  async getAllByAttributeType(requestData) {
    const reqModel = new REQUEST_MODELS.ATTRIBUTE_VALUE_ALL_BY_ATTRIBUTE_TYPE(
      requestData.token || "",
      requestData.belongsToAttribute || 0
    );
    const url = API_ROUTES.PRODUCT.ATTRIBUTE_VALUE.ALL_BY_ATTRIBUTE_TYPE;
    return await postCRUDRequest(url, reqModel);
  },
  async create(requestData, requestPermissionID) {
    const reqModel = new REQUEST_MODELS.ATTRIBUTE_VALUE_CREATE(
      requestData.token || "",
      requestData.sn || 0,
      requestData.idToAttributeValues || "",
      requestData.belongsToAttribute || 0,
      requestData.chName || "",
      requestData.enName || "",
      requestData.miscellaneous || "",
      requestData.annotation || "",
      requestData.quantity || 0,
      requestData.unitSn || 0
    );
    let url = API_ROUTES.PRODUCT.ATTRIBUTE_VALUE.CREATE;
    url = replaceUrl(url, "permissionId", requestPermissionID);
    return await postCRUDRequest(url, reqModel);
  },
  async createBatch(requestDataList, requestPermissionID) {
    const reqModels = requestDataList.map((requestData) => {
      return new REQUEST_MODELS.ATTRIBUTE_VALUE_CREATE(
        requestData.token || "",
        requestData.sn || 0,
        requestData.idToAttributeValues || "",
        requestData.belongsToAttribute || 0,
        requestData.chName || "",
        requestData.enName || "",
        requestData.miscellaneous || "",
        requestData.annotation || "",
        requestData.quantity || 0,
        requestData.unitSn || 0
      );
    });
    let url = API_ROUTES.PRODUCT.ATTRIBUTE_VALUE.CREATE_BATCH;
    url = replaceUrl(url, "permissionId", requestPermissionID);
    return await postCRUDRequest(url, reqModels);
  },
  async read(requestID) {
    const token = store.state.auth.token;
    const reqModel = new REQUEST_MODELS.ATTRIBUTE_VALUE_READ(token);
    let url = API_ROUTES.PRODUCT.ATTRIBUTE_VALUE.READ;
    url = replaceUrl(url, "id", requestID);
    try {
      const response = await axios.post(url, reqModel, {
        headers: headers,
      });
      return response.data;
    } catch (error) {
      console.error("Error in AttributeValue: ", error);
    }
  },
  async update(requestData, requestPermissionID) {
    const reqModel = new REQUEST_MODELS.ATTRIBUTE_VALUE_UPDATE(
      requestData.token || "",
      requestData.sn || 0,
      requestData.idToAttributeValues || "",
      requestData.belongsToAttribute || 0,
      requestData.chName || "",
      requestData.enName || "",
      requestData.miscellaneous || "",
      requestData.annotation || "",
      requestData.quantity || 0,
      requestData.unitSn || 0
    );
    let url = API_ROUTES.PRODUCT.ATTRIBUTE_VALUE.UPDATE;
    url = replaceUrl(url, "permissionId", requestPermissionID);
    return await postCRUDRequest(url, reqModel);
  },
  async delete(requestData, requestPermissionID) {
    const reqModel = new REQUEST_MODELS.ATTRIBUTE_VALUE_DELETE(
      requestData.token || "",
      requestData.sn || 0,
      requestData.idToAttributeValues || "",
      requestData.belongsToAttribute || 0,
      requestData.chName || "",
      requestData.enName || "",
      requestData.miscellaneous || "",
      requestData.annotation || "",
      requestData.quantity || 0,
      requestData.unitSn || 0
    );
    let url = API_ROUTES.PRODUCT.ATTRIBUTE_VALUE.DELETE;
    url = replaceUrl(url, "permissionId", requestPermissionID);
    return await postCRUDRequest(url, reqModel);
  },
  async deleteBatch(requestDataList, requestPermissionID) {
    const reqModels = requestDataList.map((requestData) => {
      return new REQUEST_MODELS.ATTRIBUTE_VALUE_DELETE(
        requestData.token || "",
        requestData.sn || 0,
        requestData.idToAttributeValues || "",
        requestData.belongsToAttribute || 0,
        requestData.chName || "",
        requestData.enName || "",
        requestData.miscellaneous || "",
        requestData.annotation || "",
        requestData.quantity || 0,
        requestData.unitSn || 0
      );
    });
    let url = API_ROUTES.PRODUCT.ATTRIBUTE_VALUE.DELETE_BATCH;
    url = replaceUrl(url, "permissionId", requestPermissionID);
    return await postCRUDRequest(url, reqModels);
  },
};

const PostProcessingType = {
  async getAll() {
    const token = store.state.auth.token;
    const reqModel = new REQUEST_MODELS.POST_PROCESSING_TYPE_ALL(token);
    const url = API_ROUTES.PRODUCT.POST_PROCESSING_TYPE.ALL;
    try {
      const response = await postRequestWithRetry(url, reqModel);
      return response;
    } catch (error) {
      console.error("Error in PostProcessingType getAll: ", error);
    }
  },
  async create(requestData, requestPermissionID) {
    const reqModel = new REQUEST_MODELS.POST_PROCESSING_TYPE_CREATE(
      requestData.token || "",
      requestData.sn || 0,
      requestData.postProcId || "",
      requestData.postProcName || "",
      requestData.annotation || ""
    );
    let url = API_ROUTES.PRODUCT.POST_PROCESSING_TYPE.CREATE;
    url = replaceUrl(url, "permissionId", requestPermissionID);
    return await postCRUDRequest(url, reqModel);
  },
  async read(requestID) {
    const token = store.state.auth.token;
    const reqModel = new REQUEST_MODELS.POST_PROCESSING_TYPE_READ(token);
    let url = API_ROUTES.PRODUCT.POST_PROCESSING_TYPE.READ;
    url = replaceUrl(url, "id", requestID);
    try {
      const response = await axios.post(url, reqModel, {
        headers: headers,
      });
      return response.data;
    } catch (error) {
      console.error("Error in PostProcessingType: ", error);
    }
  },
  async update(requestData, requestPermissionID) {
    const reqModel = new REQUEST_MODELS.POST_PROCESSING_TYPE_UPDATE(
      requestData.token || "",
      requestData.sn || 0,
      requestData.postProcId || "",
      requestData.postProcName || "",
      requestData.annotation || ""
    );
    let url = API_ROUTES.PRODUCT.POST_PROCESSING_TYPE.UPDATE;
    url = replaceUrl(url, "permissionId", requestPermissionID);
    return await postCRUDRequest(url, reqModel);
  },
  async delete(requestData, requestPermissionID) {
    const reqModel = new REQUEST_MODELS.POST_PROCESSING_TYPE_DELETE(
      requestData.token || "",
      requestData.sn || 0,
      requestData.postProcId || "",
      requestData.postProcName || "",
      requestData.annotation || ""
    );
    let url = API_ROUTES.PRODUCT.POST_PROCESSING_TYPE.DELETE;
    url = replaceUrl(url, "permissionId", requestPermissionID);
    return await postCRUDRequest(url, reqModel);
  },
};

const PostProcessingPrimaryValue = {
  async getAll() {
    const token = store.state.auth.token;
    const reqModel = new REQUEST_MODELS.POST_PROCESSING_PRIMARY_VALUE_ALL(
      token
    );
    const url = API_ROUTES.PRODUCT.POST_PROCESSING_PRIMARY_VALUE.ALL;
    try {
      const response = await postRequestWithRetry(url, reqModel);
      return response;
    } catch (error) {
      console.error("Error in PostProcessingPrimaryValue getAll: ", error);
    }
  },
  async create(requestData, requestPermissionID) {
    const reqModel = new REQUEST_MODELS.POST_PROCESSING_PRIMARY_VALUE_CREATE(
      requestData.token || "",
      requestData.sn || 0,
      requestData.idToPostProcValues || "",
      requestData.belongsToPostProc || 0,
      requestData.chName || "",
      requestData.enName || "",
      requestData.mainValue || "",
      requestData.optionType || 0,
      requestData.miscellaneous || "",
      requestData.annotation || ""
    );
    let url = API_ROUTES.PRODUCT.POST_PROCESSING_PRIMARY_VALUE.CREATE;
    url = replaceUrl(url, "permissionId", requestPermissionID);
    return await postCRUDRequest(url, reqModel);
  },
  async read(requestID) {
    const token = store.state.auth.token;
    const reqModel = new REQUEST_MODELS.POST_PROCESSING_PRIMARY_VALUE_READ(
      token
    );
    let url = API_ROUTES.PRODUCT.POST_PROCESSING_PRIMARY_VALUE.READ;
    url = replaceUrl(url, "id", requestID);
    try {
      const response = await axios.post(url, reqModel, {
        headers: headers,
      });
      return response.data;
    } catch (error) {
      console.error("Error in PostProcessingPrimaryValue: ", error);
    }
  },
  async update(requestData, requestPermissionID) {
    const reqModel = new REQUEST_MODELS.POST_PROCESSING_PRIMARY_VALUE_UPDATE(
      requestData.token || "",
      requestData.sn || 0,
      requestData.idToPostProcValues || "",
      requestData.belongsToPostProc || 0,
      requestData.chName || "",
      requestData.enName || "",
      requestData.mainValue || "",
      requestData.optionType || 0,
      requestData.miscellaneous || "",
      requestData.annotation || ""
    );
    let url = API_ROUTES.PRODUCT.POST_PROCESSING_PRIMARY_VALUE.UPDATE;
    url = replaceUrl(url, "permissionId", requestPermissionID);
    return await postCRUDRequest(url, reqModel);
  },
  async delete(requestData, requestPermissionID) {
    const reqModel = new REQUEST_MODELS.POST_PROCESSING_PRIMARY_VALUE_DELETE(
      requestData.token || "",
      requestData.sn || 0,
      requestData.idToPostProcValues || "",
      requestData.belongsToPostProc || 0,
      requestData.chName || "",
      requestData.enName || "",
      requestData.mainValue || "",
      requestData.optionType || 0,
      requestData.miscellaneous || "",
      requestData.annotation || ""
    );
    let url = API_ROUTES.PRODUCT.POST_PROCESSING_PRIMARY_VALUE.DELETE;
    url = replaceUrl(url, "permissionId", requestPermissionID);
    return await postCRUDRequest(url, reqModel);
  },
};

const PostProcessingSecondaryValue = {
  async getAll() {
    const token = store.state.auth.token;
    const reqModel = new REQUEST_MODELS.POST_PROCESSING_SECONDARY_VALUE_ALL(
      token
    );
    const url = API_ROUTES.PRODUCT.POST_PROCESSING_SECONDARY_VALUE.ALL;
    try {
      const response = await postRequestWithRetry(url, reqModel);
      return response;
    } catch (error) {
      console.error("Error in PostProcessingSecondaryValue getAll: ", error);
    }
  },
  async create(requestData, requestPermissionID) {
    const reqModel = new REQUEST_MODELS.POST_PROCESSING_SECONDARY_VALUE_CREATE(
      requestData.token || "",
      requestData.sn || 0,
      requestData.idToPostProcPrimaryValues || "",
      requestData.belongsToMainValue || 0,
      requestData.chName || "",
      requestData.enName || "",
      requestData.primaryValue || "",
      requestData.optionType || 0,
      requestData.miscellaneous || "",
      requestData.annotation || ""
    );
    let url = API_ROUTES.PRODUCT.POST_PROCESSING_SECONDARY_VALUE.CREATE;
    url = replaceUrl(url, "permissionId", requestPermissionID);
    return await postCRUDRequest(url, reqModel);
  },
  async read(requestID) {
    const token = store.state.auth.token;
    const reqModel = new REQUEST_MODELS.POST_PROCESSING_SECONDARY_VALUE_READ(
      token
    );
    let url = API_ROUTES.PRODUCT.POST_PROCESSING_SECONDARY_VALUE.READ;
    url = replaceUrl(url, "id", requestID);
    try {
      const response = await axios.post(url, reqModel, {
        headers: headers,
      });
      return response.data;
    } catch (error) {
      console.error("Error in PostProcessingSecondaryValue: ", error);
    }
  },
  async update(requestData, requestPermissionID) {
    const reqModel = new REQUEST_MODELS.POST_PROCESSING_SECONDARY_VALUE_UPDATE(
      requestData.token || "",
      requestData.sn || 0,
      requestData.idToPostProcPrimaryValues || "",
      requestData.belongsToMainValue || 0,
      requestData.chName || "",
      requestData.enName || "",
      requestData.primaryValue || "",
      requestData.optionType || 0,
      requestData.miscellaneous || "",
      requestData.annotation || ""
    );
    let url = API_ROUTES.PRODUCT.POST_PROCESSING_SECONDARY_VALUE.UPDATE;
    url = replaceUrl(url, "permissionId", requestPermissionID);
    return await postCRUDRequest(url, reqModel);
  },
  async delete(requestData, requestPermissionID) {
    const reqModel = new REQUEST_MODELS.POST_PROCESSING_SECONDARY_VALUE_DELETE(
      requestData.token || "",
      requestData.sn || 0,
      requestData.idToPostProcPrimaryValues || "",
      requestData.belongsToMainValue || 0,
      requestData.chName || "",
      requestData.enName || "",
      requestData.primaryValue || "",
      requestData.optionType || 0,
      requestData.miscellaneous || "",
      requestData.annotation || ""
    );
    let url = API_ROUTES.PRODUCT.POST_PROCESSING_SECONDARY_VALUE.DELETE;
    url = replaceUrl(url, "permissionId", requestPermissionID);
    return await postCRUDRequest(url, reqModel);
  },
};

const PostProcessingTertiaryValue = {
  async getAll() {
    const token = store.state.auth.token;
    const reqModel = new REQUEST_MODELS.POST_PROCESSING_TERTIARY_VALUE_ALL(
      token
    );
    const url = API_ROUTES.PRODUCT.POST_PROCESSING_TERTIARY_VALUE.ALL;
    try {
      const response = await postRequestWithRetry(url, reqModel);
      return response;
    } catch (error) {
      console.error("Error in PostProcessingTertiaryValue getAll: ", error);
    }
  },
  async create(requestData, requestPermissionID) {
    const reqModel = new REQUEST_MODELS.POST_PROCESSING_TERTIARY_VALUE_CREATE(
      requestData.token || "",
      requestData.sn || 0,
      requestData.idToPostProcSecondaryValues || "",
      requestData.belongsToMainValue || 0,
      requestData.belongsToPrimaryValue || 0,
      requestData.chName || "",
      requestData.enName || "",
      requestData.secondaryValue || "",
      requestData.optionType || 0,
      requestData.miscellaneous || "",
      requestData.annotation || ""
    );
    let url = API_ROUTES.PRODUCT.POST_PROCESSING_TERTIARY_VALUE.CREATE;
    url = replaceUrl(url, "permissionId", requestPermissionID);
    return await postCRUDRequest(url, reqModel);
  },
  async read(requestID) {
    const token = store.state.auth.token;
    const reqModel = new REQUEST_MODELS.POST_PROCESSING_TERTIARY_VALUE_READ(
      token
    );
    let url = API_ROUTES.PRODUCT.POST_PROCESSING_TERTIARY_VALUE.READ;
    url = replaceUrl(url, "id", requestID);
    try {
      const response = await axios.post(url, reqModel, {
        headers: headers,
      });
      return response.data;
    } catch (error) {
      console.error("Error in PostProcessingTertiaryValue: ", error);
    }
  },
  async update(requestData, requestPermissionID) {
    const reqModel = new REQUEST_MODELS.POST_PROCESSING_TERTIARY_VALUE_UPDATE(
      requestData.token || "",
      requestData.sn || 0,
      requestData.idToPostProcSecondaryValues || "",
      requestData.belongsToMainValue || 0,
      requestData.belongsToPrimaryValue || 0,
      requestData.chName || "",
      requestData.enName || "",
      requestData.secondaryValue || "",
      requestData.optionType || 0,
      requestData.miscellaneous || "",
      requestData.annotation || ""
    );
    let url = API_ROUTES.PRODUCT.POST_PROCESSING_TERTIARY_VALUE.UPDATE;
    url = replaceUrl(url, "permissionId", requestPermissionID);
    return await postCRUDRequest(url, reqModel);
  },
  async delete(requestData, requestPermissionID) {
    const reqModel = new REQUEST_MODELS.POST_PROCESSING_TERTIARY_VALUE_DELETE(
      requestData.token || "",
      requestData.sn || 0,
      requestData.idToPostProcSecondaryValues || "",
      requestData.belongsToMainValue || 0,
      requestData.belongsToPrimaryValue || 0,
      requestData.chName || "",
      requestData.enName || "",
      requestData.secondaryValue || "",
      requestData.optionType || 0,
      requestData.miscellaneous || "",
      requestData.annotation || ""
    );
    let url = API_ROUTES.PRODUCT.POST_PROCESSING_TERTIARY_VALUE.DELETE;
    url = replaceUrl(url, "permissionId", requestPermissionID);
    return await postCRUDRequest(url, reqModel);
  },
};

const PostProcessingQuaternaryValue = {
  async getAll() {
    const token = store.state.auth.token;
    const reqModel = new REQUEST_MODELS.POST_PROCESSING_QUATERNARY_VALUE_ALL(
      token
    );
    const url = API_ROUTES.PRODUCT.POST_PROCESSING_QUATERNARY_VALUE.ALL;
    try {
      const response = await postRequestWithRetry(url, reqModel);
      return response;
    } catch (error) {
      console.error("Error in PostProcessingQuaternaryValue getAll: ", error);
    }
  },
  async create(requestData, requestPermissionID) {
    const reqModel = new REQUEST_MODELS.POST_PROCESSING_QUATERNARY_VALUE_CREATE(
      requestData.token || "",
      requestData.sn || 0,
      requestData.idToPostProcTertiaryValues || "",
      requestData.belongsToMainValue || 0,
      requestData.belongsToPrimaryValue || 0,
      requestData.belongsToSecondaryValue || 0,
      requestData.chName || "",
      requestData.enName || "",
      requestData.tertiaryValue || "",
      requestData.optionType || 0,
      requestData.miscellaneous || "",
      requestData.annotation || ""
    );
    let url = API_ROUTES.PRODUCT.POST_PROCESSING_QUATERNARY_VALUE.CREATE;
    url = replaceUrl(url, "permissionId", requestPermissionID);
    return await postCRUDRequest(url, reqModel);
  },
  async read(requestID) {
    const token = store.state.auth.token;
    const reqModel = new REQUEST_MODELS.POST_PROCESSING_QUATERNARY_VALUE_READ(
      token
    );
    let url = API_ROUTES.PRODUCT.POST_PROCESSING_QUATERNARY_VALUE.READ;
    url = replaceUrl(url, "id", requestID);
    try {
      const response = await axios.post(url, reqModel, {
        headers: headers,
      });
      return response.data;
    } catch (error) {
      console.error("Error in PostProcessingQuaternaryValue: ", error);
    }
  },
  async update(requestData, requestPermissionID) {
    const reqModel = new REQUEST_MODELS.POST_PROCESSING_QUATERNARY_VALUE_UPDATE(
      requestData.token || "",
      requestData.sn || 0,
      requestData.idToPostProcTertiaryValues || "",
      requestData.belongsToMainValue || 0,
      requestData.belongsToPrimaryValue || 0,
      requestData.belongsToSecondaryValue || 0,
      requestData.chName || "",
      requestData.enName || "",
      requestData.tertiaryValue || "",
      requestData.optionType || 0,
      requestData.miscellaneous || "",
      requestData.annotation || ""
    );
    let url = API_ROUTES.PRODUCT.POST_PROCESSING_QUATERNARY_VALUE.UPDATE;
    url = replaceUrl(url, "permissionId", requestPermissionID);
    return await postCRUDRequest(url, reqModel);
  },
  async delete(requestData, requestPermissionID) {
    const reqModel = new REQUEST_MODELS.POST_PROCESSING_QUATERNARY_VALUE_DELETE(
      requestData.token || "",
      requestData.sn || 0,
      requestData.idToPostProcTertiaryValues || "",
      requestData.belongsToMainValue || 0,
      requestData.belongsToPrimaryValue || 0,
      requestData.belongsToSecondaryValue || 0,
      requestData.chName || "",
      requestData.enName || "",
      requestData.tertiaryValue || "",
      requestData.optionType || 0,
      requestData.miscellaneous || "",
      requestData.annotation || ""
    );
    let url = API_ROUTES.PRODUCT.POST_PROCESSING_QUATERNARY_VALUE.DELETE;
    url = replaceUrl(url, "permissionId", requestPermissionID);
    return await postCRUDRequest(url, reqModel);
  },
};

const ProductWithAttributeTypeConfiguration = {
  async getAll() {
    const token = store.state.auth.token;
    const reqModel =
      new REQUEST_MODELS.PRODUCT_WITH_ATTRIBUTE_TYPE_CONFIGURATION_ALL(token);
    const url =
      API_ROUTES.PRODUCT.PRODUCT_WITH_ATTRIBUTE_TYPE_CONFIGURATION.ALL;
    try {
      const response = await postRequestWithRetry(url, reqModel);
      return response;
    } catch (error) {
      console.error(
        "Error in ProductWithAttributeTypeConfiguration getAll: ",
        error
      );
    }
  },
  async getAllByProduct(requestData) {
    const reqModel =
      new REQUEST_MODELS.PRODUCT_WITH_ATTRIBUTE_TYPE_CONFIGURATION_ALL_BY_PRODUCT(
        requestData.token || "",
        requestData.product3_SN || 0
      );
    const url =
      API_ROUTES.PRODUCT.PRODUCT_WITH_ATTRIBUTE_TYPE_CONFIGURATION
        .ALL_BY_PRODUCT;
    return await postCRUDRequest(url, reqModel);
  },
  async create(requestData, requestPermissionID) {
    const reqModel =
      new REQUEST_MODELS.PRODUCT_WITH_ATTRIBUTE_TYPE_CONFIGURATION_CREATE(
        requestData.token || "",
        requestData.productAttributeSn || 0,
        requestData.product3_SN || 0,
        requestData.attributeSn || 0,
        requestData.orderByNum || 0,
        requestData.prodAttrVal_Master_type || 0
      );
    let url =
      API_ROUTES.PRODUCT.PRODUCT_WITH_ATTRIBUTE_TYPE_CONFIGURATION.CREATE;
    url = replaceUrl(url, "permissionId", requestPermissionID);
    return await postCRUDRequest(url, reqModel);
  },
  async read(requestID) {
    const token = store.state.auth.token;
    const reqModel =
      new REQUEST_MODELS.PRODUCT_WITH_ATTRIBUTE_TYPE_CONFIGURATION_READ(token);
    let url = API_ROUTES.PRODUCT.PRODUCT_WITH_ATTRIBUTE_TYPE_CONFIGURATION.READ;
    url = replaceUrl(url, "id", requestID);
    try {
      const response = await axios.post(url, reqModel, {
        headers: headers,
      });
      return response.data;
    } catch (error) {
      console.error("Error in ProductWithAttributeTypeConfiguration: ", error);
    }
  },
  async update(requestData, requestPermissionID) {
    const reqModel =
      new REQUEST_MODELS.PRODUCT_WITH_ATTRIBUTE_TYPE_CONFIGURATION_UPDATE(
        requestData.token || "",
        requestData.productAttributeSn || 0,
        requestData.product3_SN || 0,
        requestData.attributeSn || 0,
        requestData.orderByNum || 0,
        requestData.prodAttrVal_Master_type || 0
      );
    let url =
      API_ROUTES.PRODUCT.PRODUCT_WITH_ATTRIBUTE_TYPE_CONFIGURATION.UPDATE;
    url = replaceUrl(url, "permissionId", requestPermissionID);
    return await postCRUDRequest(url, reqModel);
  },
  async delete(requestData, requestPermissionID) {
    const reqModel =
      new REQUEST_MODELS.PRODUCT_WITH_ATTRIBUTE_TYPE_CONFIGURATION_DELETE(
        requestData.token || "",
        requestData.productAttributeSn || 0,
        requestData.product3_SN || 0,
        requestData.attributeSn || 0,
        requestData.orderByNum || 0,
        requestData.prodAttrVal_Master_type || 0
      );
    let url =
      API_ROUTES.PRODUCT.PRODUCT_WITH_ATTRIBUTE_TYPE_CONFIGURATION.DELETE;
    url = replaceUrl(url, "permissionId", requestPermissionID);
    return await postCRUDRequest(url, reqModel);
  },
};

const ProductWithAttributeValueConfiguration = {
  async getAll() {
    const token = store.state.auth.token;
    const reqModel =
      new REQUEST_MODELS.PRODUCT_WITH_ATTRIBUTE_VALUE_CONFIGURATION_ALL(token);
    const url =
      API_ROUTES.PRODUCT.PRODUCT_WITH_ATTRIBUTE_VALUE_CONFIGURATION.ALL;
    try {
      const response = await postRequestWithRetry(url, reqModel);
      return response;
    } catch (error) {
      console.error(
        "Error in ProductWithAttributeValueConfiguration getAll: ",
        error
      );
    }
  },
  async create(requestData, requestPermissionID) {
    const reqModel =
      new REQUEST_MODELS.PRODUCT_WITH_ATTRIBUTE_VALUE_CONFIGURATION_CREATE(
        requestData.token || "",
        requestData.sn || 0,
        requestData.productAttributeSn1 || 0,
        requestData.productAttributeSn2 || 0,
        requestData.productAttributeSn3 || 0,
        requestData.attributeValueSn1 || 0,
        requestData.attributeValueSn2 || 0,
        requestData.attributeValueSn3 || 0,
        requestData.vendors || "[]"
      );
    let url =
      API_ROUTES.PRODUCT.PRODUCT_WITH_ATTRIBUTE_VALUE_CONFIGURATION.CREATE;
    url = replaceUrl(url, "permissionId", requestPermissionID);
    return await postCRUDRequest(url, reqModel);
  },
  async createBatch(requestDataList, requestPermissionID) {
    const reqModels = requestDataList.map((requestData) => {
      return new REQUEST_MODELS.PRODUCT_WITH_ATTRIBUTE_VALUE_CONFIGURATION_CREATE(
        requestData.token || "",
        requestData.sn || 0,
        requestData.productAttributeSn1 || 0,
        requestData.productAttributeSn2 || 0,
        requestData.productAttributeSn3 || 0,
        requestData.attributeValueSn1 || 0,
        requestData.attributeValueSn2 || 0,
        requestData.attributeValueSn3 || 0,
        requestData.vendors || "[]"
      );
    });
    let url =
      API_ROUTES.PRODUCT.PRODUCT_WITH_ATTRIBUTE_VALUE_CONFIGURATION
        .CREATE_BATCH;
    url = replaceUrl(url, "permissionId", requestPermissionID);
    return await postCRUDRequest(url, reqModels);
  },
  async read(requestID) {
    const token = store.state.auth.token;
    const reqModel =
      new REQUEST_MODELS.PRODUCT_WITH_ATTRIBUTE_VALUE_CONFIGURATION_READ(token);
    let url =
      API_ROUTES.PRODUCT.PRODUCT_WITH_ATTRIBUTE_VALUE_CONFIGURATION.READ;
    url = replaceUrl(url, "id", requestID);
    try {
      const response = await axios.post(url, reqModel, {
        headers: headers,
      });
      return response.data;
    } catch (error) {
      console.error("Error in ProductWithAttributeValueConfiguration: ", error);
    }
  },
  async update(requestData, requestPermissionID) {
    const reqModel =
      new REQUEST_MODELS.PRODUCT_WITH_ATTRIBUTE_VALUE_CONFIGURATION_UPDATE(
        requestData.token || "",
        requestData.sn || 0,
        requestData.productAttributeSn1 || 0,
        requestData.productAttributeSn2 || 0,
        requestData.productAttributeSn3 || 0,
        requestData.attributeValueSn1 || 0,
        requestData.attributeValueSn2 || 0,
        requestData.attributeValueSn3 || 0,
        requestData.vendors || "[]"
      );
    let url =
      API_ROUTES.PRODUCT.PRODUCT_WITH_ATTRIBUTE_VALUE_CONFIGURATION.UPDATE;
    url = replaceUrl(url, "permissionId", requestPermissionID);
    return await postCRUDRequest(url, reqModel);
  },
  async delete(requestData, requestPermissionID) {
    const reqModel =
      new REQUEST_MODELS.PRODUCT_WITH_ATTRIBUTE_VALUE_CONFIGURATION_DELETE(
        requestData.token || "",
        requestData.sn || 0,
        requestData.productAttributeSn1 || 0,
        requestData.productAttributeSn2 || 0,
        requestData.productAttributeSn3 || 0,
        requestData.attributeValueSn1 || 0,
        requestData.attributeValueSn2 || 0,
        requestData.attributeValueSn3 || 0,
        requestData.vendors || "[]"
      );
    let url =
      API_ROUTES.PRODUCT.PRODUCT_WITH_ATTRIBUTE_VALUE_CONFIGURATION.DELETE;
    url = replaceUrl(url, "permissionId", requestPermissionID);
    return await postCRUDRequest(url, reqModel);
  },
  async deleteBatch(requestDataList, requestPermissionID) {
    const reqModels = requestDataList.map((requestData) => {
      return new REQUEST_MODELS.PRODUCT_WITH_ATTRIBUTE_VALUE_CONFIGURATION_DELETE(
        requestData.token || "",
        requestData.sn || 0,
        requestData.productAttributeSn1 || 0,
        requestData.productAttributeSn2 || 0,
        requestData.productAttributeSn3 || 0,
        requestData.attributeValueSn1 || 0,
        requestData.attributeValueSn2 || 0,
        requestData.attributeValueSn3 || 0,
        requestData.vendors || "[]"
      );
    });
    let url =
      API_ROUTES.PRODUCT.PRODUCT_WITH_ATTRIBUTE_VALUE_CONFIGURATION
        .DELETE_BATCH;
    url = replaceUrl(url, "permissionId", requestPermissionID);
    return await postCRUDRequest(url, reqModels);
  },
  async replaceBatchAdvanced(requestDataList, requestPermissionID) {
    const reqModels = requestDataList.map((requestData) => {
      return new REQUEST_MODELS.PRODUCT_WITH_ATTRIBUTE_VALUE_CONFIGURATION_REPLACE_BATCH_ADVANCED(
        requestData.token || "",
        requestData.sn || 0,
        requestData.productAttributeSn1 || 0,
        requestData.productAttributeSn2 || 0,
        requestData.productAttributeSn3 || 0,
        requestData.attributeValueSn1 || 0,
        requestData.attributeValueSn2 || 0,
        requestData.attributeValueSn3 || 0,
        requestData.vendors || "[]"
      );
    });
    let url =
      API_ROUTES.PRODUCT.PRODUCT_WITH_ATTRIBUTE_VALUE_CONFIGURATION
        .REPLACE_BATCH_ADVANCED;
    url = replaceUrl(url, "permissionId", requestPermissionID);
    return await postCRUDRequest(url, reqModels);
  },
  async replaceBatchMulti(requestDataList, requestPermissionID) {
    const reqModels = requestDataList.map((requestData) => {
      return new REQUEST_MODELS.PRODUCT_WITH_ATTRIBUTE_VALUE_CONFIGURATION_REPLACE_BATCH_MULTI(
        requestData.token || "",
        requestData.sn || 0,
        requestData.productAttributeSn1 || 0,
        requestData.productAttributeSn2 || 0,
        requestData.productAttributeSn3 || 0,
        requestData.attributeValueSn1 || 0,
        requestData.attributeValueSn2 || 0,
        requestData.attributeValueSn3 || 0,
        requestData.vendors || "[]"
      );
    });
    let url =
      API_ROUTES.PRODUCT.PRODUCT_WITH_ATTRIBUTE_VALUE_CONFIGURATION
        .REPLACE_BATCH_MULTI;
    url = replaceUrl(url, "permissionId", requestPermissionID);
    return await postCRUDRequest(url, reqModels);
  },
};

const ProductWithAttributeConfiguration = {
  async getAllByProductAttributes(requestData) {
    const reqModel =
      new REQUEST_MODELS.PRODUCT_WITH_ATTRIBUTE_CONFIGURATION_ALL_BY_PRODUCT_ATTRIBUTES(
        requestData.token || "",
        requestData.product3_SN || 0,
        requestData.productAttributeSn1 || 0,
        requestData.productAttributeSn2 || 0,
        requestData.productAttributeSn3 || 0
      );
    const url =
      API_ROUTES.PRODUCT.PRODUCT_WITH_ATTRIBUTE_CONFIGURATION
        .ALL_BY_PRODUCT_ATTRIBUTES;
    return await postCRUDRequest(url, reqModel);
  },
};

const PrintingVendor = {
  async getAll() {
    const token = store.state.auth.token;
    const reqModel = new REQUEST_MODELS.PRINTING_VENDOR_ALL(token);
    const url = API_ROUTES.PRODUCT.PRINTING_VENDOR.ALL;
    try {
      const response = await postRequestWithRetry(url, reqModel);
      return response;
    } catch (error) {
      console.error("Error in PrintingVendor getAll: ", error);
    }
  },
  async create(requestData, requestPermissionID) {
    const reqModel = new REQUEST_MODELS.PRINTING_VENDOR_CREATE(
      requestData.token || "",
      requestData.vendorSn || 0,
      requestData.chName || "",
      requestData.enName || ""
    );
    let url = API_ROUTES.PRODUCT.PRINTING_VENDOR.CREATE;
    url = replaceUrl(url, "permissionId", requestPermissionID);
    return await postCRUDRequest(url, reqModel);
  },
  async read(requestID) {
    const token = store.state.auth.token;
    const reqModel = new REQUEST_MODELS.PRINTING_VENDOR_READ(token);
    let url = API_ROUTES.PRODUCT.PRINTING_VENDOR.READ;
    url = replaceUrl(url, "id", requestID);
    try {
      const response = await axios.post(url, reqModel, {
        headers: headers,
      });
      return response.data;
    } catch (error) {
      console.error("Error in PrintingVendor: ", error);
    }
  },
  async update(requestData, requestPermissionID) {
    const reqModel = new REQUEST_MODELS.PRINTING_VENDOR_UPDATE(
      requestData.token || "",
      requestData.vendorSn || 0,
      requestData.chName || "",
      requestData.enName || ""
    );
    let url = API_ROUTES.PRODUCT.PRINTING_VENDOR.UPDATE;
    url = replaceUrl(url, "permissionId", requestPermissionID);
    return await postCRUDRequest(url, reqModel);
  },
  async delete(requestData, requestPermissionID) {
    const reqModel = new REQUEST_MODELS.PRINTING_VENDOR_DELETE(
      requestData.token || "",
      requestData.vendorSn || 0,
      requestData.chName || "",
      requestData.enName || ""
    );
    let url = API_ROUTES.PRODUCT.PRINTING_VENDOR.DELETE;
    url = replaceUrl(url, "permissionId", requestPermissionID);
    return await postCRUDRequest(url, reqModel);
  },
};

const PrintUnit = {
  async getAll() {
    const token = store.state.auth.token;
    const reqModel = new REQUEST_MODELS.PRINT_UNIT_ALL(token);
    const url = API_ROUTES.PRODUCT.PRINT_UNIT.ALL;
    try {
      const response = await postRequestWithRetry(url, reqModel);
      return response;
    } catch (error) {
      console.error("Error in PrintUnit getAll: ", error);
    }
  },
  async create(requestData, requestPermissionID) {
    const reqModel = new REQUEST_MODELS.PRINT_UNIT_CREATE(
      requestData.token || "",
      requestData.sn || 0,
      requestData.chUnitName || "",
      requestData.enUnitName || ""
    );
    let url = API_ROUTES.PRODUCT.PRINT_UNIT.CREATE;
    url = replaceUrl(url, "permissionId", requestPermissionID);
    return await postCRUDRequest(url, reqModel);
  },
  async read(requestID) {
    const token = store.state.auth.token;
    const reqModel = new REQUEST_MODELS.PRINT_UNIT_READ(token);
    let url = API_ROUTES.PRODUCT.PRINT_UNIT.READ;
    url = replaceUrl(url, "id", requestID);
    try {
      const response = await axios.post(url, reqModel, {
        headers: headers,
      });
      return response.data;
    } catch (error) {
      console.error("Error in PrintUnit: ", error);
    }
  },
  async update(requestData, requestPermissionID) {
    const reqModel = new REQUEST_MODELS.PRINT_UNIT_UPDATE(
      requestData.token || "",
      requestData.sn || 0,
      requestData.chUnitName || "",
      requestData.enUnitName || ""
    );
    let url = API_ROUTES.PRODUCT.PRINT_UNIT.UPDATE;
    url = replaceUrl(url, "permissionId", requestPermissionID);
    return await postCRUDRequest(url, reqModel);
  },
  async delete(requestData, requestPermissionID) {
    const reqModel = new REQUEST_MODELS.PRINT_UNIT_DELETE(
      requestData.token || "",
      requestData.sn || 0,
      requestData.chUnitName || "",
      requestData.enUnitName || ""
    );
    let url = API_ROUTES.PRODUCT.PRINT_UNIT.DELETE;
    url = replaceUrl(url, "permissionId", requestPermissionID);
    return await postCRUDRequest(url, reqModel);
  },
};

export default {
  ProductWithAttributeTypeConfiguration,
  ProductWithAttributeValueConfiguration,
  ProductWithAttributeConfiguration,
  PrintingVendor,
  PrintUnit,
  Type,
  Category,
  Class,
  Model,
  AttributeType,
  AttributeValue,
  PostProcessingType,
  PostProcessingPrimaryValue,
  PostProcessingSecondaryValue,
  PostProcessingTertiaryValue,
  PostProcessingQuaternaryValue,
};
