/**
 * 模組代碼與ICON對應表
 */
const moduleRoutenameIconPair = {
  "System-Management": "", // 系統管理
  "Human-Resource-Management": "", // 人資管理
  "Prodcut-Management": "", // 商品管理
};

/**
 * 選單代碼與ICON對應表
 */
const menuRoutenameIconPair = {
  // 系統管理 SM10
  "System-Configuration-Management": "", // 系統配置管理
  "System-Permission-Template-Management": "", // 系統權限樣本管理
  "Functionality-Permission-Management": "", // 功能權限管理
  "Organization-Management": "", // 權限組織管理
  "Corporation-Information-Management": "", // 企業與組織部門管理
  // 人資管理 HR11
  "Employee-Information-Management": "", // 員工資料管理
  // 商品管理 PM12
  "Product-Definition-Management": "", // 商品定義管理
  "Product-Category-Management": "", // 商品分類管理
};

/**
 * 功能代碼與ICON對應表
 */
const functionRoutenameIconPair = {
  // 系統配置管理 SM10-101
  "Module-Maintenance": "",
  "Menu-Maintenance": "",
  "Function-Maintenance": "",
  // 功能權限管理 SM10-102
  "Permission-Template-Group-Maintenance": "", //lock-fill
  "Actual-Group-Configuration": "",
  "Permission-Actual-Item-And-Member-Maintenance": "",
  // 組織管理 SM10-103
  "Employee-And-Department-Configuration": "",
  "Department-Configuration": "",
  "Organization-Department-Level-Configuration": "",
  "Job-Title-Configuration": "",
  // 企業資料管理 SM10-104
  "Corporation-And-Brand-Information-Maintenance": "",
  "Company-Maintenance": "",
  "Subsidiary-Company-Maintenance": "",
  "Store-Maintenance": "",
  // 員工資料管理 HR11-111
  "Employee-Information-Maintenance": "people-fill", // 員工資料維護
  "New-Employee-Creation": "person-plus-fill", // 員工資料新增
  "Employee-Information-Entry": "pencil-fill", // 員工資料填寫
  "Personal-Information-Form": "", // 訪客個人資料填寫
  // 員工與組織管理 HR11-113
  "Employee-And-Department-Maintenance": "",
  // 商品定義管理 PM12-121
  "Product-Attribute-Type-Overview-Maintenance": "",
  "Product-Attribute-Value-Overview-Maintenance": "",
  "Product-Post-Processsing-Type-Overview-Maintenance": "",
  "Product-Post-Processsing-Value-Overview-Maintenance": "",
  "Post-Processsing-Style": "",
  // 商品分類管理 PM12-122
  "Product-Category-Class-Model-Maintenance": "",
  "Product-Configuration-Maintenance": "",
  "Product-Combination-Maintenance": "", // 不開放
  "Product-Category-Maintenance": "", // 不開放
  "Product-Class-And-Model-Maintenance": "", // 不開放
  // 合作廠商管理 PM12-127
  "Printing-Vendor-Information-Maintenance": "",
  // 計量單位管理 PM12-128
  "Print-Unit-Maintenance": "",
};

export default {
  moduleRoutenameIconPair,
  menuRoutenameIconPair,
  functionRoutenameIconPair,
};
