// TYPE
class TYPE_ALL {
  constructor(token = "") {
    this.token = String(token);
  }
}
class TYPE_CREATE {
  constructor(
    token = "",
    product0_SN = 0,
    product0_Id = "",
    product0_ChName = "",
    product0_EnName = ""
  ) {
    this.token = String(token);
    this.product0_SN = parseInt(product0_SN);
    this.product0_Id = String(product0_Id);
    this.product0_ChName = String(product0_ChName);
    this.product0_EnName = String(product0_EnName);
  }
}
class TYPE_READ {
  constructor(token = "") {
    this.token = String(token);
  }
}
class TYPE_UPDATE {
  constructor(
    token = "",
    product0_SN = 0,
    product0_Id = "",
    product0_ChName = "",
    product0_EnName = ""
  ) {
    this.token = String(token);
    this.product0_SN = parseInt(product0_SN);
    this.product0_Id = String(product0_Id);
    this.product0_ChName = String(product0_ChName);
    this.product0_EnName = String(product0_EnName);
  }
}
class TYPE_DELETE {
  constructor(
    token = "",
    product0_SN = 0,
    product0_Id = "",
    product0_ChName = "",
    product0_EnName = ""
  ) {
    this.token = String(token);
    this.product0_SN = parseInt(product0_SN);
    this.product0_Id = String(product0_Id);
    this.product0_ChName = String(product0_ChName);
    this.product0_EnName = String(product0_EnName);
  }
}

// CATEGORY
class CATEGORY_ALL {
  constructor(token = "") {
    this.token = String(token);
  }
}
class CATEGORY_CREATE {
  constructor(
    token = "",
    product1_SN = 0,
    product1_Id = "",
    product1_ChName = "",
    product1_EnName = "",
    product0_SN = 0
  ) {
    this.token = String(token);
    this.product1_SN = parseInt(product1_SN);
    this.product1_Id = String(product1_Id);
    this.product1_ChName = String(product1_ChName);
    this.product1_EnName = String(product1_EnName);
    this.product0_SN = parseInt(product0_SN);
  }
}
class CATEGORY_READ {
  constructor(token = "") {
    this.token = String(token);
  }
}
class CATEGORY_UPDATE {
  constructor(
    token = "",
    product1_SN = 0,
    product1_Id = "",
    product1_ChName = "",
    product1_EnName = "",
    product0_SN = 0
  ) {
    this.token = String(token);
    this.product1_SN = parseInt(product1_SN);
    this.product1_Id = String(product1_Id);
    this.product1_ChName = String(product1_ChName);
    this.product1_EnName = String(product1_EnName);
    this.product0_SN = parseInt(product0_SN);
  }
}
class CATEGORY_DELETE {
  constructor(
    token = "",
    product1_SN = 0,
    product1_Id = "",
    product1_ChName = "",
    product1_EnName = "",
    product0_SN = 0
  ) {
    this.token = String(token);
    this.product1_SN = parseInt(product1_SN);
    this.product1_Id = String(product1_Id);
    this.product1_ChName = String(product1_ChName);
    this.product1_EnName = String(product1_EnName);
    this.product0_SN = parseInt(product0_SN);
  }
}

// CLASS
class CLASS_ALL {
  constructor(token = "") {
    this.token = String(token);
  }
}
class CLASS_CREATE {
  constructor(
    token = "",
    product2_SN = 0,
    product2_Id = "",
    product2_ChName = "",
    product2_EnName = "",
    product1_SN = 0
  ) {
    this.token = String(token);
    this.product2_SN = parseInt(product2_SN);
    this.product2_Id = String(product2_Id);
    this.product2_ChName = String(product2_ChName);
    this.product2_EnName = String(product2_EnName);
    this.product1_SN = parseInt(product1_SN);
  }
}
class CLASS_READ {
  constructor(token = "") {
    this.token = String(token);
  }
}
class CLASS_UPDATE {
  constructor(
    token = "",
    product2_SN = 0,
    product2_Id = "",
    product2_ChName = "",
    product2_EnName = "",
    product1_SN = 0
  ) {
    this.token = String(token);
    this.product2_SN = parseInt(product2_SN);
    this.product2_Id = String(product2_Id);
    this.product2_ChName = String(product2_ChName);
    this.product2_EnName = String(product2_EnName);
    this.product1_SN = parseInt(product1_SN);
  }
}
class CLASS_DELETE {
  constructor(
    token = "",
    product2_SN = 0,
    product2_Id = "",
    product2_ChName = "",
    product2_EnName = "",
    product1_SN = 0
  ) {
    this.token = String(token);
    this.product2_SN = parseInt(product2_SN);
    this.product2_Id = String(product2_Id);
    this.product2_ChName = String(product2_ChName);
    this.product2_EnName = String(product2_EnName);
    this.product1_SN = parseInt(product1_SN);
  }
}

// MODEL
class MODEL_ALL {
  constructor(token = "") {
    this.token = String(token);
  }
}
class MODEL_CREATE {
  constructor(
    token = "",
    product3_SN = 0,
    product3_Id = "",
    product3_ChName = "",
    product3_EnName = "",
    product1_SN = 0,
    product2_SN = 0
  ) {
    this.token = String(token);
    this.product3_SN = parseInt(product3_SN);
    this.product3_Id = String(product3_Id);
    this.product3_ChName = String(product3_ChName);
    this.product3_EnName = String(product3_EnName);
    this.product1_SN = parseInt(product1_SN);
    this.product2_SN = parseInt(product2_SN);
  }
}
class MODEL_READ {
  constructor(token = "") {
    this.token = String(token);
  }
}
class MODEL_UPDATE {
  constructor(
    token = "",
    product3_SN = 0,
    product3_Id = "",
    product3_ChName = "",
    product3_EnName = "",
    product1_SN = 0,
    product2_SN = 0
  ) {
    this.token = String(token);
    this.product3_SN = parseInt(product3_SN);
    this.product3_Id = String(product3_Id);
    this.product3_ChName = String(product3_ChName);
    this.product3_EnName = String(product3_EnName);
    this.product1_SN = parseInt(product1_SN);
    this.product2_SN = parseInt(product2_SN);
  }
}
class MODEL_DELETE {
  constructor(
    token = "",
    product3_SN = 0,
    product3_Id = "",
    product3_ChName = "",
    product3_EnName = "",
    product1_SN = 0,
    product2_SN = 0
  ) {
    this.token = String(token);
    this.product3_SN = parseInt(product3_SN);
    this.product3_Id = String(product3_Id);
    this.product3_ChName = String(product3_ChName);
    this.product3_EnName = String(product3_EnName);
    this.product1_SN = parseInt(product1_SN);
    this.product2_SN = parseInt(product2_SN);
  }
}

// ATTRIBUTE_TYPE
class ATTRIBUTE_TYPE_ALL {
  constructor(token = "") {
    this.token = String(token);
  }
}
class ATTRIBUTE_TYPE_CREATE {
  constructor(
    token = "",
    sn = 0,
    attributeId = "",
    attributeName = "",
    annotation = ""
  ) {
    this.token = String(token);
    this.sn = String(sn);
    this.attributeId = String(attributeId);
    this.attributeName = String(attributeName);
    this.annotation = String(annotation);
  }
}
class ATTRIBUTE_TYPE_READ {
  constructor(token = "") {
    this.token = String(token);
  }
}
class ATTRIBUTE_TYPE_UPDATE {
  constructor(
    token = "",
    sn = 0,
    attributeId = "",
    attributeName = "",
    annotation = ""
  ) {
    this.token = String(token);
    this.sn = String(sn);
    this.attributeId = String(attributeId);
    this.attributeName = String(attributeName);
    this.annotation = String(annotation);
  }
}
class ATTRIBUTE_TYPE_DELETE {
  constructor(
    token = "",
    sn = 0,
    attributeId = "",
    attributeName = "",
    annotation = ""
  ) {
    this.token = String(token);
    this.sn = String(sn);
    this.attributeId = String(attributeId);
    this.attributeName = String(attributeName);
    this.annotation = String(annotation);
  }
}

// ATTRIBUTE VALUE
class ATTRIBUTE_VALUE_ALL {
  constructor(token = "") {
    this.token = String(token);
  }
}
class ATTRIBUTE_VALUE_ALL_BY_ATTRIBUTE_TYPE {
  constructor(token = "", belongsToAttribute = 0) {
    this.token = String(token);
    this.belongsToAttribute = parseInt(belongsToAttribute);
  }
}
class ATTRIBUTE_VALUE_CREATE {
  constructor(
    token = "",
    sn = 0,
    idToAttributeValues = "",
    belongsToAttribute = 0,
    chName = "",
    enName = "",
    miscellaneous = "",
    annotation = "",
    quantity = 0,
    unitSn = 0
  ) {
    this.token = String(token);
    this.sn = String(sn);
    this.idToAttributeValues = String(idToAttributeValues);
    this.belongsToAttribute = parseInt(belongsToAttribute);
    this.chName = String(chName);
    this.enName = String(enName);
    this.miscellaneous = String(miscellaneous);
    this.annotation = String(annotation);
    this.quantity = parseInt(quantity);
    this.unitSn = parseInt(unitSn);
  }
}
class ATTRIBUTE_VALUE_READ {
  constructor(token = "") {
    this.token = String(token);
  }
}
class ATTRIBUTE_VALUE_UPDATE {
  constructor(
    token = "",
    sn = 0,
    idToAttributeValues = "",
    belongsToAttribute = 0,
    chName = "",
    enName = "",
    miscellaneous = "",
    annotation = "",
    quantity = 0,
    unitSn = 0
  ) {
    this.token = String(token);
    this.sn = String(sn);
    this.idToAttributeValues = String(idToAttributeValues);
    this.belongsToAttribute = parseInt(belongsToAttribute);
    this.chName = String(chName);
    this.enName = String(enName);
    this.miscellaneous = String(miscellaneous);
    this.annotation = String(annotation);
    this.quantity = parseInt(quantity);
    this.unitSn = parseInt(unitSn);
  }
}
class ATTRIBUTE_VALUE_DELETE {
  constructor(
    token = "",
    sn = 0,
    idToAttributeValues = "",
    belongsToAttribute = 0,
    chName = "",
    enName = "",
    miscellaneous = "",
    annotation = "",
    quantity = 0,
    unitSn = 0
  ) {
    this.token = String(token);
    this.sn = String(sn);
    this.idToAttributeValues = String(idToAttributeValues);
    this.belongsToAttribute = parseInt(belongsToAttribute);
    this.chName = String(chName);
    this.enName = String(enName);
    this.miscellaneous = String(miscellaneous);
    this.annotation = String(annotation);
    this.quantity = parseInt(quantity);
    this.unitSn = parseInt(unitSn);
  }
}

// POST PROCESSING TYPE
class POST_PROCESSING_TYPE_ALL {
  constructor(token = "") {
    this.token = String(token);
  }
}
class POST_PROCESSING_TYPE_CREATE {
  constructor(
    token = "",
    sn = 0,
    postProcId = "",
    postProcName = "",
    annotation = ""
  ) {
    this.token = String(token);
    this.sn = String(sn);
    this.postProcId = String(postProcId);
    this.postProcName = String(postProcName);
    this.annotation = String(annotation);
  }
}
class POST_PROCESSING_TYPE_READ {
  constructor(token = "") {
    this.token = String(token);
  }
}
class POST_PROCESSING_TYPE_UPDATE {
  constructor(
    token = "",
    sn = 0,
    postProcId = "",
    postProcName = "",
    annotation = ""
  ) {
    this.token = String(token);
    this.sn = String(sn);
    this.postProcId = String(postProcId);
    this.postProcName = String(postProcName);
    this.annotation = String(annotation);
  }
}
class POST_PROCESSING_TYPE_DELETE {
  constructor(
    token = "",
    sn = 0,
    postProcId = "",
    postProcName = "",
    annotation = ""
  ) {
    this.token = String(token);
    this.sn = String(sn);
    this.postProcId = String(postProcId);
    this.postProcName = String(postProcName);
    this.annotation = String(annotation);
  }
}

// POST PROCESSING PRIMARY VALUE
class POST_PROCESSING_PRIMARY_VALUE_ALL {
  constructor(token = "") {
    this.token = String(token);
  }
}
class POST_PROCESSING_PRIMARY_VALUE_CREATE {
  constructor(
    token = "",
    sn = 0,
    idToPostProcValues = "",
    belongsToPostProc = 0,
    chName = "",
    enName = "",
    mainValue = "",
    optionType = "",
    miscellaneous = "",
    annotation = ""
  ) {
    this.token = String(token);
    this.sn = String(sn);
    this.idToPostProcValues = String(idToPostProcValues);
    this.belongsToPostProc = parseInt(belongsToPostProc);
    this.chName = String(chName);
    this.enName = String(enName);
    this.mainValue = String(mainValue);
    this.optionType = parseInt(optionType);
    this.miscellaneous = String(miscellaneous);
    this.annotation = String(annotation);
  }
}
class POST_PROCESSING_PRIMARY_VALUE_READ {
  constructor(token = "") {
    this.token = String(token);
  }
}
class POST_PROCESSING_PRIMARY_VALUE_UPDATE {
  constructor(
    token = "",
    sn = 0,
    idToPostProcValues = "",
    belongsToPostProc = 0,
    chName = "",
    enName = "",
    mainValue = "",
    optionType = "",
    miscellaneous = "",
    annotation = ""
  ) {
    this.token = String(token);
    this.sn = String(sn);
    this.idToPostProcValues = String(idToPostProcValues);
    this.belongsToPostProc = parseInt(belongsToPostProc);
    this.chName = String(chName);
    this.enName = String(enName);
    this.mainValue = String(mainValue);
    this.optionType = parseInt(optionType);
    this.miscellaneous = String(miscellaneous);
    this.annotation = String(annotation);
  }
}
class POST_PROCESSING_PRIMARY_VALUE_DELETE {
  constructor(
    token = "",
    sn = 0,
    idToPostProcValues = "",
    belongsToPostProc = 0,
    chName = "",
    enName = "",
    mainValue = "",
    optionType = "",
    miscellaneous = "",
    annotation = ""
  ) {
    this.token = String(token);
    this.sn = String(sn);
    this.idToPostProcValues = String(idToPostProcValues);
    this.belongsToPostProc = parseInt(belongsToPostProc);
    this.chName = String(chName);
    this.enName = String(enName);
    this.mainValue = String(mainValue);
    this.optionType = parseInt(optionType);
    this.miscellaneous = String(miscellaneous);
    this.annotation = String(annotation);
  }
}

// POST PROCESSING SECONDARY VALUE
class POST_PROCESSING_SECONDARY_VALUE_ALL {
  constructor(token = "") {
    this.token = String(token);
  }
}
class POST_PROCESSING_SECONDARY_VALUE_CREATE {
  constructor(
    token = "",
    sn = 0,
    idToPostProcPrimaryValues = "",
    belongsToMainValue = 0,
    chName = "",
    enName = "",
    primaryValue = "",
    optionType = "",
    miscellaneous = "",
    annotation = ""
  ) {
    this.token = String(token);
    this.sn = String(sn);
    this.idToPostProcPrimaryValues = String(idToPostProcPrimaryValues);
    this.belongsToMainValue = parseInt(belongsToMainValue);
    this.chName = String(chName);
    this.enName = String(enName);
    this.primaryValue = String(primaryValue);
    this.optionType = parseInt(optionType);
    this.miscellaneous = String(miscellaneous);
    this.annotation = String(annotation);
  }
}
class POST_PROCESSING_SECONDARY_VALUE_READ {
  constructor(token = "") {
    this.token = String(token);
  }
}
class POST_PROCESSING_SECONDARY_VALUE_UPDATE {
  constructor(
    token = "",
    sn = 0,
    idToPostProcPrimaryValues = "",
    belongsToMainValue = 0,
    chName = "",
    enName = "",
    primaryValue = "",
    optionType = "",
    miscellaneous = "",
    annotation = ""
  ) {
    this.token = String(token);
    this.sn = String(sn);
    this.idToPostProcPrimaryValues = String(idToPostProcPrimaryValues);
    this.belongsToMainValue = parseInt(belongsToMainValue);
    this.chName = String(chName);
    this.enName = String(enName);
    this.primaryValue = String(primaryValue);
    this.optionType = parseInt(optionType);
    this.miscellaneous = String(miscellaneous);
    this.annotation = String(annotation);
  }
}
class POST_PROCESSING_SECONDARY_VALUE_DELETE {
  constructor(
    token = "",
    sn = 0,
    idToPostProcPrimaryValues = "",
    belongsToMainValue = 0,
    chName = "",
    enName = "",
    primaryValue = "",
    optionType = "",
    miscellaneous = "",
    annotation = ""
  ) {
    this.token = String(token);
    this.sn = String(sn);
    this.idToPostProcPrimaryValues = String(idToPostProcPrimaryValues);
    this.belongsToMainValue = parseInt(belongsToMainValue);
    this.chName = String(chName);
    this.enName = String(enName);
    this.primaryValue = String(primaryValue);
    this.optionType = parseInt(optionType);
    this.miscellaneous = String(miscellaneous);
    this.annotation = String(annotation);
  }
}

// POST PROCESSING TERTIARY VALUE
class POST_PROCESSING_TERTIARY_VALUE_ALL {
  constructor(token = "") {
    this.token = String(token);
  }
}
class POST_PROCESSING_TERTIARY_VALUE_CREATE {
  constructor(
    token = "",
    sn = 0,
    idToPostProcSecondaryValues = "",
    belongsToMainValue = 0,
    belongsToPrimaryValue = 0,
    chName = "",
    enName = "",
    secondaryValue = "",
    optionType = "",
    miscellaneous = "",
    annotation = ""
  ) {
    this.token = String(token);
    this.sn = String(sn);
    this.idToPostProcSecondaryValues = String(idToPostProcSecondaryValues);
    this.belongsToMainValue = parseInt(belongsToMainValue);
    this.belongsToPrimaryValue = parseInt(belongsToPrimaryValue);
    this.chName = String(chName);
    this.enName = String(enName);
    this.secondaryValue = String(secondaryValue);
    this.optionType = parseInt(optionType);
    this.miscellaneous = String(miscellaneous);
    this.annotation = String(annotation);
  }
}
class POST_PROCESSING_TERTIARY_VALUE_READ {
  constructor(token = "") {
    this.token = String(token);
  }
}
class POST_PROCESSING_TERTIARY_VALUE_UPDATE {
  constructor(
    token = "",
    sn = 0,
    idToPostProcSecondaryValues = "",
    belongsToMainValue = 0,
    belongsToPrimaryValue = 0,
    chName = "",
    enName = "",
    secondaryValue = "",
    optionType = "",
    miscellaneous = "",
    annotation = ""
  ) {
    this.token = String(token);
    this.sn = String(sn);
    this.idToPostProcSecondaryValues = String(idToPostProcSecondaryValues);
    this.belongsToMainValue = parseInt(belongsToMainValue);
    this.belongsToPrimaryValue = parseInt(belongsToPrimaryValue);
    this.chName = String(chName);
    this.enName = String(enName);
    this.secondaryValue = String(secondaryValue);
    this.optionType = parseInt(optionType);
    this.miscellaneous = String(miscellaneous);
    this.annotation = String(annotation);
  }
}
class POST_PROCESSING_TERTIARY_VALUE_DELETE {
  constructor(
    token = "",
    sn = 0,
    idToPostProcSecondaryValues = "",
    belongsToMainValue = 0,
    belongsToPrimaryValue = 0,
    chName = "",
    enName = "",
    secondaryValue = "",
    optionType = "",
    miscellaneous = "",
    annotation = ""
  ) {
    this.token = String(token);
    this.sn = String(sn);
    this.idToPostProcSecondaryValues = String(idToPostProcSecondaryValues);
    this.belongsToMainValue = parseInt(belongsToMainValue);
    this.belongsToPrimaryValue = parseInt(belongsToPrimaryValue);
    this.chName = String(chName);
    this.enName = String(enName);
    this.secondaryValue = String(secondaryValue);
    this.optionType = parseInt(optionType);
    this.miscellaneous = String(miscellaneous);
    this.annotation = String(annotation);
  }
}

// POST PROCESSING QUATERNARY VALUE
class POST_PROCESSING_QUATERNARY_VALUE_ALL {
  constructor(token = "") {
    this.token = String(token);
  }
}
class POST_PROCESSING_QUATERNARY_VALUE_CREATE {
  constructor(
    token = "",
    sn = 0,
    idToPostProcTertiaryValues = "",
    belongsToMainValue = 0,
    belongsToPrimaryValue = 0,
    belongsToSecondaryValue = 0,
    chName = "",
    enName = "",
    tertiaryValue = "",
    optionType = "",
    miscellaneous = "",
    annotation = ""
  ) {
    this.token = String(token);
    this.sn = String(sn);
    this.idToPostProcTertiaryValues = String(idToPostProcTertiaryValues);
    this.belongsToMainValue = parseInt(belongsToMainValue);
    this.belongsToPrimaryValue = parseInt(belongsToPrimaryValue);
    this.belongsToSecondaryValue = parseInt(belongsToSecondaryValue);
    this.chName = String(chName);
    this.enName = String(enName);
    this.tertiaryValue = String(tertiaryValue);
    this.optionType = parseInt(optionType);
    this.miscellaneous = String(miscellaneous);
    this.annotation = String(annotation);
  }
}
class POST_PROCESSING_QUATERNARY_VALUE_READ {
  constructor(token = "") {
    this.token = String(token);
  }
}
class POST_PROCESSING_QUATERNARY_VALUE_UPDATE {
  constructor(
    token = "",
    sn = 0,
    idToPostProcTertiaryValues = "",
    belongsToMainValue = 0,
    belongsToPrimaryValue = 0,
    belongsToSecondaryValue = 0,
    chName = "",
    enName = "",
    tertiaryValue = "",
    optionType = "",
    miscellaneous = "",
    annotation = ""
  ) {
    this.token = String(token);
    this.sn = String(sn);
    this.idToPostProcTertiaryValues = String(idToPostProcTertiaryValues);
    this.belongsToMainValue = parseInt(belongsToMainValue);
    this.belongsToPrimaryValue = parseInt(belongsToPrimaryValue);
    this.belongsToSecondaryValue = parseInt(belongsToSecondaryValue);
    this.chName = String(chName);
    this.enName = String(enName);
    this.tertiaryValue = String(tertiaryValue);
    this.optionType = parseInt(optionType);
    this.miscellaneous = String(miscellaneous);
    this.annotation = String(annotation);
  }
}
class POST_PROCESSING_QUATERNARY_VALUE_DELETE {
  constructor(
    token = "",
    sn = 0,
    idToPostProcTertiaryValues = "",
    belongsToMainValue = 0,
    belongsToPrimaryValue = 0,
    belongsToSecondaryValue = 0,
    chName = "",
    enName = "",
    tertiaryValue = "",
    optionType = "",
    miscellaneous = "",
    annotation = ""
  ) {
    this.token = String(token);
    this.sn = String(sn);
    this.idToPostProcTertiaryValues = String(idToPostProcTertiaryValues);
    this.belongsToMainValue = parseInt(belongsToMainValue);
    this.belongsToPrimaryValue = parseInt(belongsToPrimaryValue);
    this.belongsToSecondaryValue = parseInt(belongsToSecondaryValue);
    this.chName = String(chName);
    this.enName = String(enName);
    this.tertiaryValue = String(tertiaryValue);
    this.optionType = parseInt(optionType);
    this.miscellaneous = String(miscellaneous);
    this.annotation = String(annotation);
  }
}

// PRODUCT WITH ATTRIBUTE TYPE CONFIGURATION
class PRODUCT_WITH_ATTRIBUTE_TYPE_CONFIGURATION_ALL {
  constructor(token = "") {
    this.token = String(token);
  }
}
class PRODUCT_WITH_ATTRIBUTE_TYPE_CONFIGURATION_ALL_BY_PRODUCT {
  constructor(token = "", product3_SN = 0) {
    this.token = String(token);
    this.product3_SN = parseInt(product3_SN);
  }
}
class PRODUCT_WITH_ATTRIBUTE_TYPE_CONFIGURATION_CREATE {
  constructor(
    token = "",
    productAttributeSn = 0,
    product3_SN = 0,
    attributeSn = 0,
    orderByNum = 0,
    prodAttrVal_Master_type = 0
  ) {
    this.token = String(token);
    this.productAttributeSn = parseInt(productAttributeSn);
    this.product3_SN = parseInt(product3_SN);
    this.attributeSn = parseInt(attributeSn);
    this.orderByNum = parseInt(orderByNum);
    this.prodAttrVal_Master_type = parseInt(prodAttrVal_Master_type);
  }
}
class PRODUCT_WITH_ATTRIBUTE_TYPE_CONFIGURATION_READ {
  constructor(token = "") {
    this.token = String(token);
  }
}
class PRODUCT_WITH_ATTRIBUTE_TYPE_CONFIGURATION_UPDATE {
  constructor(
    token = "",
    productAttributeSn = 0,
    product3_SN = 0,
    attributeSn = 0,
    orderByNum = 0,
    prodAttrVal_Master_type = 0
  ) {
    this.token = String(token);
    this.productAttributeSn = parseInt(productAttributeSn);
    this.product3_SN = parseInt(product3_SN);
    this.attributeSn = parseInt(attributeSn);
    this.orderByNum = parseInt(orderByNum);
    this.prodAttrVal_Master_type = parseInt(prodAttrVal_Master_type);
  }
}
class PRODUCT_WITH_ATTRIBUTE_TYPE_CONFIGURATION_DELETE {
  constructor(
    token = "",
    productAttributeSn = 0,
    product3_SN = 0,
    attributeSn = 0,
    orderByNum = 0,
    prodAttrVal_Master_type = 0
  ) {
    this.token = String(token);
    this.productAttributeSn = parseInt(productAttributeSn);
    this.product3_SN = parseInt(product3_SN);
    this.attributeSn = parseInt(attributeSn);
    this.orderByNum = parseInt(orderByNum);
    this.prodAttrVal_Master_type = parseInt(prodAttrVal_Master_type);
  }
}

// PRODUCT WITH ATTRIBUTE VALUE CONFIGURATION
class PRODUCT_WITH_ATTRIBUTE_VALUE_CONFIGURATION_ALL {
  constructor(token = "") {
    this.token = String(token);
  }
}
class PRODUCT_WITH_ATTRIBUTE_VALUE_CONFIGURATION_CREATE {
  constructor(
    token = "",
    sn = 0,
    productAttributeSn1 = 0,
    productAttributeSn2 = 0,
    productAttributeSn3 = 0,
    attributeValueSn1 = 0,
    attributeValueSn2 = 0,
    attributeValueSn3 = 0,
    vendors = ""
  ) {
    this.token = String(token);
    this.sn = parseInt(sn);
    this.productAttributeSn1 = parseInt(productAttributeSn1);
    this.productAttributeSn2 = parseInt(productAttributeSn2);
    this.productAttributeSn3 = parseInt(productAttributeSn3);
    this.attributeValueSn1 = parseInt(attributeValueSn1);
    this.attributeValueSn2 = parseInt(attributeValueSn2);
    this.attributeValueSn3 = parseInt(attributeValueSn3);
    this.vendors = String(vendors);
  }
}
class PRODUCT_WITH_ATTRIBUTE_VALUE_CONFIGURATION_READ {
  constructor(token = "") {
    this.token = String(token);
  }
}
class PRODUCT_WITH_ATTRIBUTE_VALUE_CONFIGURATION_UPDATE {
  constructor(
    token = "",
    sn = 0,
    productAttributeSn1 = 0,
    productAttributeSn2 = 0,
    productAttributeSn3 = 0,
    attributeValueSn1 = 0,
    attributeValueSn2 = 0,
    attributeValueSn3 = 0,
    vendors = ""
  ) {
    this.token = String(token);
    this.sn = parseInt(sn);
    this.productAttributeSn1 = parseInt(productAttributeSn1);
    this.productAttributeSn2 = parseInt(productAttributeSn2);
    this.productAttributeSn3 = parseInt(productAttributeSn3);
    this.attributeValueSn1 = parseInt(attributeValueSn1);
    this.attributeValueSn2 = parseInt(attributeValueSn2);
    this.attributeValueSn3 = parseInt(attributeValueSn3);
    this.vendors = String(vendors);
  }
}
class PRODUCT_WITH_ATTRIBUTE_VALUE_CONFIGURATION_DELETE {
  constructor(
    token = "",
    sn = 0,
    productAttributeSn1 = 0,
    productAttributeSn2 = 0,
    productAttributeSn3 = 0,
    attributeValueSn1 = 0,
    attributeValueSn2 = 0,
    attributeValueSn3 = 0,
    vendors = ""
  ) {
    this.token = String(token);
    this.sn = parseInt(sn);
    this.productAttributeSn1 = parseInt(productAttributeSn1);
    this.productAttributeSn2 = parseInt(productAttributeSn2);
    this.productAttributeSn3 = parseInt(productAttributeSn3);
    this.attributeValueSn1 = parseInt(attributeValueSn1);
    this.attributeValueSn2 = parseInt(attributeValueSn2);
    this.attributeValueSn3 = parseInt(attributeValueSn3);
    this.vendors = String(vendors);
  }
}
class PRODUCT_WITH_ATTRIBUTE_VALUE_CONFIGURATION_REPLACE_BATCH_ADVANCED {
  constructor(
    token = "",
    sn = 0,
    productAttributeSn1 = 0,
    productAttributeSn2 = 0,
    productAttributeSn3 = 0,
    attributeValueSn1 = 0,
    attributeValueSn2 = 0,
    attributeValueSn3 = 0,
    vendors = "[]"
  ) {
    this.token = String(token);
    this.sn = parseInt(sn);
    this.productAttributeSn1 = parseInt(productAttributeSn1);
    this.productAttributeSn2 = parseInt(productAttributeSn2);
    this.productAttributeSn3 = parseInt(productAttributeSn3);
    this.attributeValueSn1 = parseInt(attributeValueSn1);
    this.attributeValueSn2 = parseInt(attributeValueSn2);
    this.attributeValueSn3 = parseInt(attributeValueSn3);
    this.vendors = String(vendors);
  }
}
class PRODUCT_WITH_ATTRIBUTE_VALUE_CONFIGURATION_REPLACE_BATCH_MULTI {
  constructor(
    token = "",
    sn = 0,
    productAttributeSn1 = 0,
    productAttributeSn2 = 0,
    productAttributeSn3 = 0,
    attributeValueSn1 = 0,
    attributeValueSn2 = 0,
    attributeValueSn3 = 0,
    vendors = "[]"
  ) {
    this.token = String(token);
    this.sn = parseInt(sn);
    this.productAttributeSn1 = parseInt(productAttributeSn1);
    this.productAttributeSn2 = parseInt(productAttributeSn2);
    this.productAttributeSn3 = parseInt(productAttributeSn3);
    this.attributeValueSn1 = parseInt(attributeValueSn1);
    this.attributeValueSn2 = parseInt(attributeValueSn2);
    this.attributeValueSn3 = parseInt(attributeValueSn3);
    this.vendors = String(vendors);
  }
}

// PRODUCT WITH ATTRIBUTE CONFIGURATION
class PRODUCT_WITH_ATTRIBUTE_CONFIGURATION_ALL_BY_PRODUCT_ATTRIBUTES {
  constructor(
    token = "",
    product3_SN = 0,
    productAttributeSn1 = 0,
    productAttributeSn2 = 0,
    productAttributeSn3 = 0
  ) {
    this.token = String(token);
    this.product3_SN = parseInt(product3_SN);
    this.productAttributeSn1 = parseInt(productAttributeSn1);
    this.productAttributeSn2 = parseInt(productAttributeSn2);
    this.productAttributeSn3 = parseInt(productAttributeSn3);
  }
}

// PRINTING VENDOR
class PRINTING_VENDOR_ALL {
  constructor(token = "") {
    this.token = String(token);
  }
}
class PRINTING_VENDOR_CREATE {
  constructor(token = "", vendorSn = 0, chName = "", enName = "") {
    this.token = String(token);
    this.vendorSn = String(vendorSn);
    this.chName = String(chName);
    this.enName = String(enName);
  }
}
class PRINTING_VENDOR_READ {
  constructor(token = "") {
    this.token = String(token);
  }
}
class PRINTING_VENDOR_UPDATE {
  constructor(token = "", vendorSn = 0, chName = "", enName = "") {
    this.token = String(token);
    this.vendorSn = String(vendorSn);
    this.chName = String(chName);
    this.enName = String(enName);
  }
}
class PRINTING_VENDOR_DELETE {
  constructor(token = "", vendorSn = 0, chName = "", enName = "") {
    this.token = String(token);
    this.vendorSn = String(vendorSn);
    this.chName = String(chName);
    this.enName = String(enName);
  }
}

// PRINT UNIT
class PRINT_UNIT_ALL {
  constructor(token = "") {
    this.token = String(token);
  }
}
class PRINT_UNIT_CREATE {
  constructor(token = "", sn = 0, chUnitName = "", enUnitName = "") {
    this.token = String(token);
    this.sn = String(sn);
    this.chUnitName = String(chUnitName);
    this.enUnitName = String(enUnitName);
  }
}
class PRINT_UNIT_READ {
  constructor(token = "") {
    this.token = String(token);
  }
}
class PRINT_UNIT_UPDATE {
  constructor(token = "", sn = 0, chUnitName = "", enUnitName = "") {
    this.token = String(token);
    this.sn = String(sn);
    this.chUnitName = String(chUnitName);
    this.enUnitName = String(enUnitName);
  }
}
class PRINT_UNIT_DELETE {
  constructor(token = "", sn = 0, chUnitName = "", enUnitName = "") {
    this.token = String(token);
    this.sn = String(sn);
    this.chUnitName = String(chUnitName);
    this.enUnitName = String(enUnitName);
  }
}

export default {
  PRODUCT_WITH_ATTRIBUTE_TYPE_CONFIGURATION_ALL,
  PRODUCT_WITH_ATTRIBUTE_TYPE_CONFIGURATION_ALL_BY_PRODUCT,
  PRODUCT_WITH_ATTRIBUTE_TYPE_CONFIGURATION_CREATE,
  PRODUCT_WITH_ATTRIBUTE_TYPE_CONFIGURATION_READ,
  PRODUCT_WITH_ATTRIBUTE_TYPE_CONFIGURATION_UPDATE,
  PRODUCT_WITH_ATTRIBUTE_TYPE_CONFIGURATION_DELETE,
  PRODUCT_WITH_ATTRIBUTE_VALUE_CONFIGURATION_ALL,
  PRODUCT_WITH_ATTRIBUTE_VALUE_CONFIGURATION_CREATE,
  PRODUCT_WITH_ATTRIBUTE_VALUE_CONFIGURATION_READ,
  PRODUCT_WITH_ATTRIBUTE_VALUE_CONFIGURATION_UPDATE,
  PRODUCT_WITH_ATTRIBUTE_VALUE_CONFIGURATION_DELETE,
  PRODUCT_WITH_ATTRIBUTE_VALUE_CONFIGURATION_REPLACE_BATCH_ADVANCED,
  PRODUCT_WITH_ATTRIBUTE_VALUE_CONFIGURATION_REPLACE_BATCH_MULTI,
  PRODUCT_WITH_ATTRIBUTE_CONFIGURATION_ALL_BY_PRODUCT_ATTRIBUTES,
  PRINTING_VENDOR_ALL,
  PRINTING_VENDOR_CREATE,
  PRINTING_VENDOR_READ,
  PRINTING_VENDOR_UPDATE,
  PRINTING_VENDOR_DELETE,
  PRINT_UNIT_ALL,
  PRINT_UNIT_CREATE,
  PRINT_UNIT_READ,
  PRINT_UNIT_UPDATE,
  PRINT_UNIT_DELETE,
  TYPE_ALL,
  TYPE_CREATE,
  TYPE_READ,
  TYPE_UPDATE,
  TYPE_DELETE,
  CATEGORY_ALL,
  CATEGORY_CREATE,
  CATEGORY_READ,
  CATEGORY_UPDATE,
  CATEGORY_DELETE,
  CLASS_ALL,
  CLASS_CREATE,
  CLASS_READ,
  CLASS_UPDATE,
  CLASS_DELETE,
  MODEL_ALL,
  MODEL_CREATE,
  MODEL_READ,
  MODEL_UPDATE,
  MODEL_DELETE,
  ATTRIBUTE_TYPE_ALL,
  ATTRIBUTE_TYPE_CREATE,
  ATTRIBUTE_TYPE_READ,
  ATTRIBUTE_TYPE_UPDATE,
  ATTRIBUTE_TYPE_DELETE,
  ATTRIBUTE_VALUE_ALL,
  ATTRIBUTE_VALUE_ALL_BY_ATTRIBUTE_TYPE,
  ATTRIBUTE_VALUE_CREATE,
  ATTRIBUTE_VALUE_READ,
  ATTRIBUTE_VALUE_UPDATE,
  ATTRIBUTE_VALUE_DELETE,
  POST_PROCESSING_TYPE_ALL,
  POST_PROCESSING_TYPE_CREATE,
  POST_PROCESSING_TYPE_READ,
  POST_PROCESSING_TYPE_UPDATE,
  POST_PROCESSING_TYPE_DELETE,
  POST_PROCESSING_PRIMARY_VALUE_ALL,
  POST_PROCESSING_PRIMARY_VALUE_CREATE,
  POST_PROCESSING_PRIMARY_VALUE_READ,
  POST_PROCESSING_PRIMARY_VALUE_UPDATE,
  POST_PROCESSING_PRIMARY_VALUE_DELETE,
  POST_PROCESSING_SECONDARY_VALUE_ALL,
  POST_PROCESSING_SECONDARY_VALUE_CREATE,
  POST_PROCESSING_SECONDARY_VALUE_READ,
  POST_PROCESSING_SECONDARY_VALUE_UPDATE,
  POST_PROCESSING_SECONDARY_VALUE_DELETE,
  POST_PROCESSING_TERTIARY_VALUE_ALL,
  POST_PROCESSING_TERTIARY_VALUE_CREATE,
  POST_PROCESSING_TERTIARY_VALUE_READ,
  POST_PROCESSING_TERTIARY_VALUE_UPDATE,
  POST_PROCESSING_TERTIARY_VALUE_DELETE,
  POST_PROCESSING_QUATERNARY_VALUE_ALL,
  POST_PROCESSING_QUATERNARY_VALUE_CREATE,
  POST_PROCESSING_QUATERNARY_VALUE_READ,
  POST_PROCESSING_QUATERNARY_VALUE_UPDATE,
  POST_PROCESSING_QUATERNARY_VALUE_DELETE,
};
