// 商品定義管理 Product Definition Management
const PM12_121_Routes = [
  {
    path: "product-definition-management",
    name: "Product-Definition-Management",
    meta: {
      title: `商品定義管理 - ${process.env.VUE_APP_COMPANY_NAME}`,
    },
  },
  {
    // 【定義】總表維護 121_1
    path: "121_1",
    name: "Product-Attribute-Type-Overview-Maintenance",
    component: () =>
      import(
        "@/views/ProductManagement/121-ProductDefinitionManagement/121_1/PM_121_1_AttributeTypeView.vue"
      ),
    meta: {
      title: `【定義】總表維護 - ${process.env.VUE_APP_COMPANY_NAME}`,
    },
  },
  {
    // 【定義資料】總表維護 121_2
    path: "121_2",
    name: "Product-Attribute-Value-Overview-Maintenance",
    component: () =>
      import(
        "@/views/ProductManagement/121-ProductDefinitionManagement/121_2/PM_121_2_AttributeValueView.vue"
      ),
    meta: {
      title: `【定義資料】總表維護 - ${process.env.VUE_APP_COMPANY_NAME}`,
    },
  },
  {
    // 【後加工】總表維護 121_3
    path: "121_3",
    name: "Product-Post-Processsing-Type-Overview-Maintenance",
    component: () =>
      import(
        "@/views/ProductManagement/121-ProductDefinitionManagement/121_3/PM_121_3_PostProcessingTypeView.vue"
      ),
    meta: {
      title: `【後加工】總表維護 - ${process.env.VUE_APP_COMPANY_NAME}`,
    },
  },
  {
    // 【後加工資料】總表維護 121_4
    path: "121_4",
    name: "Product-Post-Processsing-Value-Overview-Maintenance",
    component: () =>
      import(
        "@/views/ProductManagement/121-ProductDefinitionManagement/121_4/PM_121_4_PostProcessingValueView.vue"
      ),
    meta: {
      title: `【後加工資料】總表維護 - ${process.env.VUE_APP_COMPANY_NAME}`,
    },
  },
  {
    // 後加工樣式預覽測試 121_style
    path: "121_Style",
    name: "Post-Processsing-Style",
    component: () =>
      import(
        "@/views/ProductManagement/121-ProductDefinitionManagement/121_Style/PM_121_Style_PostProcessingStyleView.vue"
      ),
    meta: {
      title: `後加工樣式預覽測試 - ${process.env.VUE_APP_COMPANY_NAME}`,
    },
  },
];

// 商品分類管理 Product Category Management
const PM12_122_Routes = [
  {
    path: "product-category-management",
    name: "Product-Category-Management",
    meta: {
      title: `商品分類管理 - ${process.env.VUE_APP_COMPANY_NAME}`,
    },
  },
  {
    // 分類維護 122_1
    path: "122_1",
    name: "Product-Category-Class-Model-Maintenance",
    component: () =>
      import(
        "@/views/ProductManagement/122-ProductCategoryManagement/122_1/PM_122_1_CategoryClassModelView.vue"
      ),
    meta: {
      title: `分類維護 - ${process.env.VUE_APP_COMPANY_NAME}`,
    },
  },
  {
    // 合版商品定義資料維護 122_2
    path: "122_2",
    name: "Product-Configuration-Maintenance",
    component: () =>
      import(
        "@/views/ProductManagement/122-ProductCategoryManagement/122_2/PM_122_2_ProductConfigurationView.vue"
      ),
    meta: {
      title: `合版商品定義資料維護 - ${process.env.VUE_APP_COMPANY_NAME}`,
    },
  },
  {
    // 不開放
    // 大分類維護 122_10000
    path: "122_10000",
    name: "Product-Category-Maintenance",
    component: () =>
      import(
        "@/views/ProductManagement/122-ProductCategoryManagement/122_10000/XPM_122_10000_CategoryView.vue"
      ),
    meta: {
      title: `大分類維護 - ${process.env.VUE_APP_COMPANY_NAME}`,
    },
  },
  {
    // 不開放
    // 小分類和商品維護 122_20000
    path: "122_20000",
    name: "Product-Class-And-Model-Maintenance",
    component: () =>
      import(
        "@/views/ProductManagement/122-ProductCategoryManagement/122_20000/XPM_122_20000_ClassView.vue"
      ),
    meta: {
      title: `小分類和商品維護 - ${process.env.VUE_APP_COMPANY_NAME}`,
    },
  },
];

// 合作廠商管理 Vendor Management
const PM12_127_Routes = [
  {
    path: "vendor-management",
    name: "Vendor-Management",
    meta: {
      title: `合作廠商管理 - ${process.env.VUE_APP_COMPANY_NAME}`,
    },
  },
  {
    // 印刷廠商資料維護 127_1
    path: "127_1",
    name: "Printing-Vendor-Information-Maintenance",
    component: () =>
      import(
        "@/views/ProductManagement/127-VendorManagement/127_1/PM_127_1_PrintingVendorView.vue"
      ),
    meta: {
      title: `印刷廠商資料維護 - ${process.env.VUE_APP_COMPANY_NAME}`,
    },
  },
];

// 計量單位管理 Measurement Unit Management
const PM12_128_Routes = [
  {
    path: "measurement-unit-management",
    name: "Measurement-Unit-Management",
    meta: {
      title: `計量單位管理 - ${process.env.VUE_APP_COMPANY_NAME}`,
    },
  },
  {
    // 印刷單位維護 128_1
    path: "128_1",
    name: "Print-Unit-Maintenance",
    component: () =>
      import(
        "@/views/ProductManagement/128-MeasurementUnitManagement/128_1/PM_128_1_PrintUnitView.vue"
      ),
    meta: {
      title: `印刷單位維護 - ${process.env.VUE_APP_COMPANY_NAME}`,
    },
  },
];

export default {
  PM12_121_Routes,
  PM12_122_Routes,
  PM12_127_Routes,
  PM12_128_Routes,
};
